import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
    static classes = 'px-4'

    static imageStyle = 'height: 50vh;'
    static bigImageScr = 'img/logo/logo_title_big_screen.svg'
    static littleImageScr = 'img/logo/logo_title_little_screen.svg'
}

// MARK: - Class

export default class AppLogoViewModel {
  // MARK: - Properties

    /** @type {string} */
    _classes
    /** @type {ImageViewModel} */
    _bigImage
    /** @type {ImageViewModel} */
    _littleImage

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._classes = Constants.classes

      this._bigImage = new ImageViewModel()
      this._bigImage.src = Constants.bigImageScr
      this._bigImage.style = Constants.imageStyle

      this._littleImage = new ImageViewModel()
      this._littleImage.src = Constants.littleImageScr
      this._littleImage.style = Constants.imageStyle
    }

    // MARK: - Getter

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {boolean} */
    get showBigImage () {
      return AppManager.shared.vuetify.breakpoint.mdAndUp
    }

    /** @return {ImageViewModel} */
    get bigImage () {
      return this._bigImage
    }

    /** @return {boolean} */
    get showLittleImage () {
      return !this.showBigImage
    }

    /** @return {ImageViewModel} */
    get littleImage () {
      return this._littleImage
    }
}
