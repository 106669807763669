<template>
  <div>
    <!-- Title -->
    <p :class="viewModel.classes">
      {{ viewModel.text }}
      <span :class="viewModel.boldClasses">
      {{ viewModel.boldText }}
    </span>
    </p>

    <!-- Space -->
    <custom-space :view-model="viewModel.space"/>
  </div>
</template>

<script>
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'

export default {
  name: 'SectionTitleComponent',

  props: {
    viewModel: {
      type: SectionTitleViewModel,
      required: true
    }
  },

  components: {
    customSpace: SpaceComponent
  }
}
</script>
