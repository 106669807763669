import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import Localization from '@/presentation/utils/localization'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.SCHEDULES

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 7
    }

    static Title = {
      isCentered: false,
      key: 'schedules_title',
      boldKey: 'schedules_title_bold'
    }

    static Text = {
      key: 'schedules_information'
    }

    static AccountText = {
      key: 'schedules_account_description',
      classes: ' '
    }

    static AccountButton = {
      classes: 'mr-2 mb-2 ' + Color.textColor(Color.black),
      title: 'schedules_account'
    }
}

// MARK: - Class

export default class AppSchedulesViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {RowViewModel} */
    _itemRow
    /** @type {ColViewModel} */
    _itemCol
    /** @type {[AppSchedulesDayCardViewModel]} */
    _periodSchedulesCards
    /** @type {string} */
    _accountText
    /** @type {string} */
    _accountTextClasses
    /** @type {TextButtonViewModel} */
    _accountButton

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._itemRow = new RowViewModel()

      this._itemCol = new ColViewModel()

      this._periodSchedulesCards = []

      this._accountText = Localization.getText(Constants.AccountText.key)
      this._accountTextClasses = Constants.AccountText.classes

      this._accountButton = new TextButtonViewModel()
      this._accountButton.title = Localization.getText(Constants.AccountButton.title)
      this._accountButton.classes = Constants.AccountButton.classes
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {RowViewModel} */
    get itemRow () {
      return this._itemRow
    }

    /** @return {ColViewModel} */
    get itemCol () {
      return this._itemCol
    }

    /** @return {[AppSchedulesDayCardViewModel]} */
    get periodSchedulesCards () {
      return this._periodSchedulesCards
    }

    /** @return {string} */
    get accountText () {
      return this._accountText
    }

    /** @return {string} */
    get accountTextClasses () {
      return this._accountTextClasses
    }

    /** @return {TextButtonViewModel} */
    get accountButton () {
      return this._accountButton
    }

    // MARK: - Setter

    /** @param {[AppSchedulesDayCardViewModel]} value */
    set periodSchedulesCards (value) {
      this._periodSchedulesCards = value
    }
}
