import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import Color from '@/presentation/utils/color'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.APP

    static HeaderImage = {
      scr: 'img/store/stores.png',
      lazyScr: 'img/store/stores_lazy.png',
      classes: Color.main,
      contain: false,
      minHeight: '300px'
    }

    static space = PresentationSpace.CLASSIC

    static Text = {
      key: 'app_description',
      classes: ' px-4 text-center font-italic'
    }

    static StoreRow = {
      justify: PresentationJustify.CENTER
    }

    static StoreCol = {
      cols: 10,
      sm: 4,
      md: 3
    }

    static StoreItemCol = {
      cols: 6
    }

    static StoresImage = {
      scrs: ['img/store/app-store.svg', 'img/store/play-store.svg'],
      classes: 'clickable'
    }
}

// MARK: - Class

export default class AppAppsViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {ImageViewModel} */
    _headerImage
    /** @type {SpaceViewModel} */
    _headerImageBottomSpace
    /** @type {string} */
    _text
    /** @type {string} */
    _textClasses
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {RowViewModel} */
    _storeRow
    /** @type {ColViewModel} */
    _storeCol
    /** @type {RowViewModel} */
    _storeItemRow
    /** @type {ColViewModel} */
    _storeItemCol
    /** @type {[ImageViewModel]} */
    _storeImages

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)
      this._headerImage = new ImageViewModel()
      this._headerImage.src = Constants.HeaderImage.scr
      this._headerImage.lazySrc = Constants.HeaderImage.lazySrc
      this._headerImage.classes = Constants.HeaderImage.classes
      this._headerImage.contain = Constants.HeaderImage.contain
      this._headerImage.minHeight = Constants.HeaderImage.minHeight

      this._headerImageBottomSpace = new SpaceViewModel()
      this._headerImageBottomSpace.space = Constants.space

      this._text = Localization.getText(Constants.Text.key)
      this._textClasses = Constants.Text.classes

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.space

      this._storeRow = new RowViewModel()
      this._storeRow.justify = Constants.StoreRow.justify

      this._storeCol = new ColViewModel()
      this._storeCol.cols = Constants.StoreCol.cols
      this._storeCol.smAndGreater = Constants.StoreCol.sm
      this._storeCol.mdAndGreater = Constants.StoreCol.md
      this._storeCol.lgAndGreater = Constants.StoreCol.lg

      this._storeItemRow = new RowViewModel()

      this._storeItemCol = new ColViewModel()
      this._storeItemCol.cols = Constants.StoreItemCol.cols

      this._storeImages = Constants.StoresImage.scrs.map(e => {
        const item = new ImageViewModel()
        item.src = e
        item.classes = Constants.StoresImage.classes
        return item
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {ImageViewModel} */
    get headerImage () {
      return this._headerImage
    }

    /** @return {SpaceViewModel} */
    get headerImageBottomSpace () {
      return this._headerImageBottomSpace
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {string} */
    get textClasses () {
      return this._textClasses
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {RowViewModel} */
    get storeRow () {
      return this._storeRow
    }

    /** @return {ColViewModel} */
    get storeCol () {
      return this._storeCol
    }

    /** @return {RowViewModel} */
    get storeItemRow () {
      return this._storeItemRow
    }

    /** @return {ColViewModel} */
    get storeItemCol () {
      return this._storeItemCol
    }

    /** @return {[ImageViewModel]} */
    get storeImages () {
      return this._storeImages
    }
}
