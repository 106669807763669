// MARK: - Constants

const ERROR_HOME = 'home_error'
const ERROR_CONTACT = 'contact_error'
const SUCCESS_CONTACT = 'contact_success'

// MARK: - Enum

export default {
  ERROR_HOME,
  ERROR_CONTACT,
  SUCCESS_CONTACT
}
