export default class ApiViolationResponseErrorModel {
  // MARK: - Properties

  /** @propertyPath {string} */
  _propertyPath
  /** @propertyPath {string} */
  _message

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._propertyPath = response.propertyPath
    this._message = response.message
  }

  // MARK: - Getter

  /** @return {string} */
  get propertyPath () {
    return this._propertyPath
  }

  /** @return {string} */
  get message () {
    return this._message
  }
}
