<template>
  <v-rating
      :value="viewModel.value"
      :background-color="viewModel.backgroundColor"
      :color="viewModel.color"
      :half-increments="viewModel.halfIncrements"
      :readonly="viewModel.readonly"
      :length="viewModel.length"
      :size="viewModel.size"
  />
</template>

<script>
import RatingViewModel from '@/presentation/view-model/rating/ratingViewModel'

export default {
  name: 'RatingComponent',

  props: {
    viewModel: {
      type: RatingViewModel,
      required: true
    }
  }
}
</script>
