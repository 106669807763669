<template>
  <v-icon
      :class="viewModel.classes"
      :size="viewModel.size"
      :style="viewModel.style"
  >
    {{ viewModel.name }}
  </v-icon>
</template>

<script>
import IconViewModel from '@/presentation/view-model/icon/iconViewModel'

export default {
  name: 'IconComponent',

  props: {
    viewModel: {
      type: IconViewModel,
      required: true
    }
  }
}
</script>
