<template>
  <v-btn
      :class="viewModel.classes"
      :color="viewModel.color"
      :block="viewModel.block"
      :text="viewModel.onlyText"
      :loading="viewModel.isLoading"
      @click="clicked()"
    >
      {{ viewModel.title }}
  </v-btn>
</template>

<script>
import ButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'TextButtonComponent',

  props: {
    viewModel: {
      type: ButtonViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
