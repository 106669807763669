// MARK: - Constants

import Localization from '@/presentation/utils/localization'

class Constants {
    static defaultValue = 0
}

// MARK: - Class

export default class ChipViewModel {
  // MARK: - Properties

    /** @type {int|string} */
    _value
    /** @type {string|null} */
    _text

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._value = Constants.defaultValue
      this._text = null
    }

    // MARK: - Getter

    /** @return {int|string} */
    get value () {
      return this._value
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    // MARK: - Setter

    /** @param {int|string} value */
    set value (value) {
      this._value = value
    }

    /** @param {string} value */
    set textKey (value) {
      this._text = Localization.getText(value)
    }
}
