<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Card -->
      <custom-card :view-model="viewModel.contentCard">

        <!-- Title -->
        <custom-section-title :view-model="viewModel.title"/>

        <!-- Sub content -->
        <div :class="viewModel.subContentClasses">

            <!-- Rating -->
            <custom-rating :view-model="viewModel.rating"/>

            <!-- Rating space -->
            <custom-space :view-model="viewModel.ratingBottomSpace"/>

            <!-- Text -->
            <p :class="viewModel.textClasses">
              {{ viewModel.text }}
            </p>

            <!-- Asset space -->
            <custom-space :view-model="viewModel.textBottomSpace"/>

            <!-- Read Button -->
            <custom-button
                :view-model="viewModel.readButton"
                @click="readClicked()"
            />
         </div>
      </custom-card>
    </custom-col>
  </custom-row>
</template>

<script>
import AppOpinionViewModel from '@/presentation/scene/app/view-model/appOpinionViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import RatingComponent from '@/app/common/component/rating/RatingComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppConceptComponent',

  props: {
    viewModel: {
      type: AppOpinionViewModel,
      required: true
    }
  },

  components: {
    customCard: CardComponent,
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customButton: TextButtonComponent,
    customRating: RatingComponent
  },

  methods: {
    readClicked () {
      this.$emit(Events.readOpinionClicked)
    }
  }
}
</script>
