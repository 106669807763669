import String from '@/presentation/utils/string'
import Localization from '@/presentation/utils/localization'

export default class NavigationItemViewModel {
  // MARK: - Properties

    /** @type {string} */
    _key
    /** @type {string} */
    _name
    /** @type {string|null} */
    _anchor

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._key = String.empty
      this._name = String.empty
      this._anchor = null
    }

    // MARK: - Getter

    /** @return {string} */
    get key () {
      return this._key
    }

    /** @return {string} */
    get name () {
      return this._name
    }

    /** @return {string|null} */
    get anchor () {
      return this._anchor
    }

    // MARK: - Setter

    /** @param {string} value */
    set nameKey (value) {
      this._key = value
      this._name = Localization.getText(value)
    }

    /** @param {string} value */
    set name (value) {
      this._name = value
    }

    /** @param {string} value */
    set anchor (value) {
      this._anchor = value
    }
}
