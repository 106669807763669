import Icon from '@/presentation/utils/icon'
import Color from '@/presentation/utils/color'
import AppWaylfTimelineItemViewModel from '@/presentation/scene/app/view-model/waylf/appWaylfTimelineItemViewModel'

// MARK: - Constants

const ENDURANCE = 1
const WEIGHTLOSS = 2
const TONING = 3
const BODY = 4
const MIND = 5
const PAIN = 6

// MARK: - Enum

export default {
  ENDURANCE,
  WEIGHTLOSS,
  TONING,
  BODY,
  MIND,
  PAIN,

  /**
     * @return {[int]} return all enum values
     */
  all () {
    return [
      ENDURANCE,
      WEIGHTLOSS,
      TONING,
      BODY,
      MIND,
      PAIN
    ]
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {AppWaylfTimelineItemViewModel}
     */
  item (value) {
    const item = new AppWaylfTimelineItemViewModel()
    item.titleKey = this._titleKey(value)
    item.descriptionKey = this._descriptionKey(value)
    item.color = this._color(value)
    item.icon = this._icon(value)
    return item
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _titleKey (value) {
    return 'waylf_subtitle_' + value
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _descriptionKey (value) {
    return 'waylf_description_' + value
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _color (value) {
    switch (value) {
      case WEIGHTLOSS: case BODY: case PAIN:
        return Color.red
      default:
        return Color.green
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _icon (value) {
    switch (value) {
      case WEIGHTLOSS:
        return Icon.WAYLF_2
      case BODY:
        return Icon.WAYLF_3
      case TONING:
        return Icon.WAYLF_4
      case MIND:
        return Icon.WAYLF_5
      case PAIN:
        return Icon.WAYLF_6
      default:
        return Icon.WAYLF_1
    }
  }
}
