<template>
    <v-app>
      <!-- App Bar -->
      <custom-app-bar
          :view-model="presenter.viewModel.appBar"
          @menuClick="controller.menuTapped()"
          @accountClick="controller.accountTapped()"
          @navigationClick="controller.navigationItemTapped($event)"
      />

      <!-- Navigation -->
      <custom-navigation
          :view-model="presenter.viewModel.navigationMenu"
          @navigationClick="controller.navigationItemTapped($event)"
          @closeNavigationClick="controller.closeNavigationTapped()"
      />

      <!-- Body -->
      <custom-body :view-model="presenter.viewModel.body">

        <!-- Logo -->
        <custom-logo :view-model="presenter.viewModel.bodyLogo"/>

        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyConcept.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Concept -->
        <custom-concept
            :view-model="presenter.viewModel.bodyConcept"
            @assetClick="controller.assetTapped($event)"
        />

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyWaw.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Who we are ? -->
        <custom-waw
            :view-model="presenter.viewModel.bodyWaw"
            @socialClick="controller.coachSocialTapped($event)"
        />

        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

        <!-- Space background one -->
        <custom-space-background :view-model="presenter.viewModel.bodySpaceBackgroundOne"/>

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyWaylf.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- What are you looking for -->
        <custom-waylf :view-model="presenter.viewModel.bodyWaylf"/>

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodySchedules.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Schedules -->
        <custom-schedules
            :view-model="presenter.viewModel.bodySchedules"
            @accountClick="controller.accountTapped()"
        />

        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

        <!-- Space background two -->
        <custom-space-background :view-model="presenter.viewModel.bodySpaceBackgroundTwo"/>

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyPrices.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Prices -->
        <custom-prices
            :view-model="presenter.viewModel.bodyPrices"
            @trialClick="controller.trialTapped()"
        />

        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

        <!-- Catch phrase -->
        <custom-catch-phrase :view-model="presenter.viewModel.bodyCatchPhrase"/>

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyOpinion.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Opinion -->
        <custom-opinion
            :view-model="presenter.viewModel.bodyOpinion"
            @readOpinionClick="controller.readOpinionTapped()"
        />

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyApps.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Apps -->
        <custom-apps
             :view-model="presenter.viewModel.bodyApps"
             @storeClick="controller.storeTapped($event)"
        />

         <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyLocality.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Locality -->
        <custom-locality
            :view-model="presenter.viewModel.bodyLocality"
            @mapClick="controller.mapTapped($event)"
        />

        <!-- Space -->
        <custom-space
            :ref="presenter.viewModel.bodyContact.ref"
            :view-model="presenter.viewModel.bodySectionSpace"
        />

        <!-- Contact -->
        <custom-contact
            :view-model="presenter.viewModel.bodyContact"
            :vuelidate="$v"
            @contactLinkClick="controller.contactLinkTapped($event)"
            @contactSendClick="controller.contactSendTapped()"
        />

        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

        <!-- Footer -->
        <custom-footer :view-model="presenter.viewModel.bodyFooter"/>

      </custom-body>

      <!-- Popup -->
      <custom-popup :view-model="presenter.viewModel.popup"/>
    </v-app>
</template>

<script>

// Components
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import NavigationComponent from '@/app/common/component/navigation/NavigationComponent'
import BodyComponent from '@/app/common/component/body/BodyComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LogoComponent from '@/app/scene/app/component/AppLogoComponent'
import AppSpaceBackgroundComponent from '@/app/scene/app/component/AppSpaceBackgroundComponent'
import ConceptComponent from '@/app/scene/app/component/AppConceptComponent'
import WawComponent from '@/app/scene/app/component/AppWawComponent'
import WaylfComponent from '@/app/scene/app/component/AppWaylfComponent'
import SchedulesComponent from '@/app/scene/app/component/AppSchedulesComponent'
import OpinionComponent from '@/app/scene/app/component/AppOpinionComponent'
import PricesComponent from '@/app/scene/app/component/AppPricesComponent'
import CatchPhraseComponent from '@/app/scene/app/component/AppCatchPhraseComponent'
import AppsComponent from '@/app/scene/app/component/AppAppsComponent'
import LocalityComponent from '@/app/scene/app/component/AppLocalityComponent'
import ContactComponent from '@/app/scene/app/component/AppContactComponent'
import FooterComponent from '@/app/scene/app/component/AppFooterComponent'
import PopupComponent from '@/app/common/component/popup/PopupComponent'

import PresentationInjection from '@/presentation/scene/app/appPresentationInjection'
import AppPresenter from '@/presentation/scene/app/appPresenter'

export default {
  name: 'AppVue',

  components: {
    customAppBar: AppBarComponent,
    customNavigation: NavigationComponent,
    customBody: BodyComponent,
    customSpace: SpaceComponent,
    customLogo: LogoComponent,
    customSpaceBackground: AppSpaceBackgroundComponent,
    customConcept: ConceptComponent,
    customWaw: WawComponent,
    customWaylf: WaylfComponent,
    customSchedules: SchedulesComponent,
    customOpinion: OpinionComponent,
    customPrices: PricesComponent,
    customCatchPhrase: CatchPhraseComponent,
    customApps: AppsComponent,
    customLocality: LocalityComponent,
    customContact: ContactComponent,
    customFooter: FooterComponent,
    customPopup: PopupComponent
  },

  data: () => ({
    /** @type AppController */
    controller: null,
    /** @type AppPresenter */
    presenter: null,

    // Contact TextFields (NOTE: Same names are used on TextFieldViewModel,Presenter and Interactor)
    contactFormName: null,
    contactFormEmail: null,
    contactFormMessage: null
  }),

  validations: AppPresenter.textFieldsValidations,

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$v, this.$refs)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'app-style.scss'
</style>
