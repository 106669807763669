import AppBarViewModel from '@/presentation/view-model/app-bar/appBarViewModel'
import NavigationViewModel from '@/presentation/view-model/navigation/navigationViewModel'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import BodyViewModel from '@/presentation/view-model/body/bodyViewModel'
import AppLogoViewModel from '@/presentation/scene/app/view-model/appLogoViewModel'
import AppAppsViewModel from '@/presentation/scene/app/view-model/appAppsViewModel'
import AppLocalityViewModel from '@/presentation/scene/app/view-model/appLocalityViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import AppConceptViewModel from '@/presentation/scene/app/view-model/appConceptViewModel'
import AppWawViewModel from '@/presentation/scene/app/view-model/appWawViewModel'
import AppWaylfViewModel from '@/presentation/scene/app/view-model/appWaylfViewModel'
import AppSchedulesViewModel from '@/presentation/scene/app/view-model/appSchedulesViewModel'
import AppPricesViewModel from '@/presentation/scene/app/view-model/appPricesViewModel'
import AppOpinionViewModel from '@/presentation/scene/app/view-model/appOpinionViewModel'
import AppContactViewModel from '@/presentation/scene/app/view-model/appContactViewModel'
import AppCatchPhraseViewModel from '@/presentation/scene/app/view-model/appCatchPhraseViewModel'
import AppSpaceBackground from '@/presentation/scene/app/enum/appSpaceBackground'
import AppSpaceBackgroundViewModel from '@/presentation/scene/app/view-model/appSpaceBackgroundViewModel'
import PopupViewModel from '@/presentation/view-model/popup/popupViewModel'
import AppFooterViewModel from '@/presentation/scene/app/view-model/appFooterViewModel'

// MARK: - Constants

class Constants {
  static space = PresentationSpace.BIG
}

// MARK: - Class

export default class AppViewModel {
  // MARK: - Properties

  /** @type {AppBarViewModel} */
  _appBar
  /** @type {NavigationViewModel} */
  _navigationMenu
  /** @type {BodyViewModel} */
  _body
  /** @type {SpaceViewModel} */
  _bodySectionSpace
  /** @type {AppLogoViewModel} */
  _bodyLogo
  /** @type {AppConceptViewModel} */
  _bodyConcept
  /** @type {AppWawViewModel} */
  _bodyWaw
  /** @type {AppSpaceBackgroundViewModel} */
  _bodySpaceBackgroundOne
  /** @type {AppWaylfViewModel} */
  _bodyWaylf
  /** @type {AppSchedulesViewModel} */
  _bodySchedules
  /** @type {AppSpaceBackgroundViewModel} */
  _bodySpaceBackgroundTwo
  /** @type {AppPricesViewModel} */
  _bodyPrices
  /** @type {AppCatchPhraseViewModel} */
  _bodyCatchPhrase
  /** @type {AppOpinionViewModel} */
  _bodyOpinion
  /** @type {AppAppsViewModel} */
  _bodyApps
  /** @type {AppLocalityViewModel} */
  _bodyLocality
  /** @type {AppContactViewModel} */
  _bodyContact
  /** @type {AppFooterViewModel} */
  _bodyFooter
  /** @type {PopupViewModel} */
  _popup

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation
   */
  constructor (validation) {
    const items = PresentationNavigation.all()

    this._appBar = new AppBarViewModel()
    this._appBar.items = items

    this._navigationMenu = new NavigationViewModel()
    this._navigationMenu.items = items

    this._body = new BodyViewModel()
    this._bodySectionSpace = new SpaceViewModel()
    this._bodySectionSpace.space = Constants.space
    this._bodyLogo = new AppLogoViewModel()
    this._bodyConcept = new AppConceptViewModel()
    this._bodyWaw = new AppWawViewModel()

    this._bodySpaceBackgroundOne = new AppSpaceBackgroundViewModel()
    this._bodySpaceBackgroundOne.classes = AppSpaceBackground.ONE

    this._bodyWaylf = new AppWaylfViewModel()
    this._bodySchedules = new AppSchedulesViewModel()

    this._bodySpaceBackgroundTwo = new AppSpaceBackgroundViewModel()
    this._bodySpaceBackgroundTwo.classes = AppSpaceBackground.TWO

    this._bodyPrices = new AppPricesViewModel()
    this._bodyCatchPhrase = new AppCatchPhraseViewModel()
    this._bodyOpinion = new AppOpinionViewModel()
    this._bodyApps = new AppAppsViewModel()
    this._bodyLocality = new AppLocalityViewModel()
    this._bodyContact = new AppContactViewModel(validation)
    this._bodyFooter = new AppFooterViewModel()

    this._popup = new PopupViewModel()
  }

  // MARK: - Getter

  /** @return {AppBarViewModel} */
  get appBar () {
    return this._appBar
  }

  /** @return {NavigationViewModel} */
  get navigationMenu () {
    return this._navigationMenu
  }

  /** @return {BodyViewModel} */
  get body () {
    return this._body
  }

  /** @return {SpaceViewModel} */
  get bodySectionSpace () {
    return this._bodySectionSpace
  }

  /** @return {AppLogoViewModel} */
  get bodyLogo () {
    return this._bodyLogo
  }

  /** @return {AppConceptViewModel} */
  get bodyConcept () {
    return this._bodyConcept
  }

  /** @return {AppWawViewModel} */
  get bodyWaw () {
    return this._bodyWaw
  }

  /** @return {AppSpaceBackgroundViewModel} */
  get bodySpaceBackgroundOne () {
    return this._bodySpaceBackgroundOne
  }

  /** @return {AppWaylfViewModel} */
  get bodyWaylf () {
    return this._bodyWaylf
  }

  /** @return {AppSchedulesViewModel} */
  get bodySchedules () {
    return this._bodySchedules
  }

  /** @return {AppSpaceBackgroundViewModel} */
  get bodySpaceBackgroundTwo () {
    return this._bodySpaceBackgroundTwo
  }

  /** @return {AppPricesViewModel} */
  get bodyPrices () {
    return this._bodyPrices
  }

  /** @return {AppCatchPhraseViewModel} */
  get bodyCatchPhrase () {
    return this._bodyCatchPhrase
  }

  /** @return {AppOpinionViewModel} */
  get bodyOpinion () {
    return this._bodyOpinion
  }

  /** @return {AppAppsViewModel} */
  get bodyApps () {
    return this._bodyApps
  }

  /** @return {AppLocalityViewModel} */
  get bodyLocality () {
    return this._bodyLocality
  }

  /** @return {AppContactViewModel} */
  get bodyContact () {
    return this._bodyContact
  }

  /** @return {AppFooterViewModel} */
  get bodyFooter () {
    return this._bodyFooter
  }

  /** @return {PopupViewModel} */
  get popup () {
    return this._popup
  }
}
