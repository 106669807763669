import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
    static isIcon = true
    static color = Color.secondary
}

// MARK: - Class

export default class IconButtonViewModel {
  // MARK: - Properties

    /** @type {boolean} */
    _isIcon
    /** @type {string|null} */
    _classes
    /** @type {string} */
    _color
    /** @type {IconViewModel} */
    _icon

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._isIcon = Constants.isIcon
      this._classes = null
      this._color = Constants.color
      this._icon = null
    }

    // MARK: - Getter

    /** @return {boolean} */
    get isIcon () {
      return this._isIcon
    }

    /** @return {string|null} */
    get classes () {
      return this._classes
    }

    /** @return {string} */
    get color () {
      return this._color
    }

    /** @return {IconViewModel} */
    get icon () {
      return this._icon
    }

    // MARK: - Setter

    /** @param  {IconViewModel} value */
    set icon (value) {
      this._icon = value
    }

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }
}
