<template>
    <v-timeline-item
        :key="viewModel.key"
        :color="viewModel.color"
        :icon="viewModel.icon"
        :fill-dot="viewModel.fillDot"
        :large="viewModel.large"
    >
      <!-- Slot needed to put content here -->
      <slot></slot>

    </v-timeline-item>
</template>

<script>
import TimelineItemViewModel from '@/presentation/view-model/timeline/timelineItemViewModel'

export default {
  name: 'TimelineItemComponent',

  props: {
    viewModel: {
      type: TimelineItemViewModel,
      required: true
    }
  }
}
</script>
