import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import Color from '@/presentation/utils/color'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
    static classes = 'mx-auto ' + Color.grey

    static Description = {
      key: 'schedules_information'
    }

    static ContentRow = {
      classes: 'mb-12'
    }
}

// MARK: - Class

export default class AppSchedulesDayCardViewModel extends CardViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _title
    /** @type {string|null} */
    _description
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {[AppSchedulesDayCardTimeViewModel]} */
    _dayTimes

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._classes = Constants.classes

      this._title = null
      this._description = Localization.getText(Constants.Description.key)

      this._contentRow = new RowViewModel()
      this._contentRow.classes = Constants.ContentRow.classes

      this._contentCol = new ColViewModel()
      this._contentCol.cols = null

      this._dayTimes = []
    }

    // MARK: - Getter

    /** @return {string|null} */
    get title () {
      return this._title
    }

    /** @return {string|null} */
    get description () {
      return this._description
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {[AppSchedulesDayCardTimeViewModel]} */
    get dayTimes () {
      return this._dayTimes
    }

    // MARK: - Setter

    /** @param {string|null} value */
    set title (value) {
      this._title = value
    }

    /** @param {[AppSchedulesDayCardTimeViewModel]} value */
    set dayTimes (value) {
      this._dayTimes = value
    }
}
