export default class AppManager {
  // MARK: - Properties

    /** @type {AppManager|null} */
    static _shared

    // MARK: - Constructor

    /**
     * @constructor
     * @param {Framework} vuetify
     */
    constructor (vuetify) {
      this._vuetify = vuetify
    }

    // MARK: - Getter

    /** @return {AppManager} */
    static get shared () {
      return this._shared
    }

    /** @return {Framework} */
    get vuetify () {
      return this._vuetify
    }

    // MARK: - Getter

    /** @param {AppManager} value */
    static set shared (value) {
      this._shared = value
    }
}
