import AppViewModel from '@/presentation/scene/app/view-model/appViewModel'
import PresentationKey from '@/presentation/enum/presentationKey'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import AppPopupType from '@/presentation/scene/app/enum/appPopupType'
import AppManager from '@/common/manager/appManager'
import Localization from '@/presentation/utils/localization'
import AppSchedulesDayCardViewModel from '@/presentation/scene/app/view-model/schedules/appSchedulesDayCardViewModel'
import AppSchedulesDayCardTimeViewModel
from '@/presentation/scene/app/view-model/schedules/appSchedulesDayCardTimeViewModel'
import String from '@/presentation/utils/string'
import AppContactViewModel from '@/presentation/scene/app/view-model/appContactViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static scrollAfterTabsTapped = false
}

// MARK: - Class

export default class AppPresenter {
  // MARK: - Properties

  /** @type {any} */
  _validation
  /** @type {AppViewModel} */
  _viewModel
  /** @type {any} */
  _refs

  /** @type {boolean} */
  _scrollAfterTabsTapped
  /** @type {any|null} */
  _scrollAfterTabsTappedTimeout

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation
   * @param {any} refs
   */
  constructor (validation, refs) {
    this._validation = validation

    this._viewModel = new AppViewModel(validation)
    this._refs = refs
    this._scrollAfterTabsTapped = Constants.scrollAfterTabsTapped
    this._scrollAfterTabsTappedTimeout = null
  }

  // MARK: - Getter

  /** @return {AppViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return AppContactViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /**
   * @param {boolean} show or hide navigation burger
   */
  showMenuFromInteractor (show) {
    this._viewModel.navigationMenu.showContent = show
  }

  updateAppBarLogoSizeFromInteractor () {
    const selector = PresentationKey.ref(PresentationKey.SCROLLING)
    const scrollTop = document.querySelector(selector).scrollTop

    const minSize = AppManager.shared.vuetify.breakpoint.mdAndDown ? 40 : 46
    const maxSize = AppManager.shared.vuetify.breakpoint.mdAndDown ? 112 : 150

    const newValue = maxSize - scrollTop
    let size
    if (newValue > minSize) {
      size = newValue
    } else {
      size = minSize
    }
    this._viewModel.appBar.logoIcon.size = size
  }

  updateSelectedNavigationItemFromInteractor () {
    if (this._scrollAfterTabsTapped) {
      return
    }

    const items = PresentationNavigation.all()
    let selectedIndex
    items.forEach(e => {
      const element = this._refs[PresentationNavigation.anchorKey(e)].$el
      const size = element.getBoundingClientRect()

      if (size.top < 0) {
        selectedIndex = e
      }
    })

    // Default selection
    if (!selectedIndex) {
      selectedIndex = PresentationNavigation.CONCEPT
    }

    // Update tabs and navigation selected index
    this._updateAnchorSelectedIndex(selectedIndex)
  }

  /**
   * @param {string} anchor to scroll to correct div
   */
  scrollToAnchorFromInteractor (anchor) {
    // Scroll to div
    const element = this._refs[anchor].$el
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })

    // Update tabs and navigation selected index
    this._updateAnchorSelectedIndex(PresentationNavigation.value(anchor))

    // Lock update tabs on scroll for X seconds
    this._scrollAfterTabsTapped = true
    const self = this

    // Clear timeout
    if (this._scrollAfterTabsTappedTimeout) {
      clearTimeout(this._scrollAfterTabsTappedTimeout)
    }

    // Unlock update
    this._scrollAfterTabsTappedTimeout = setTimeout(function () {
      self._scrollAfterTabsTapped = false
    }, 1000)
  }

  /**
   * @param {number} value of the rating
   */
  set opinionFromInteractor (value) {
    this._viewModel.bodyOpinion.rating.value = value
  }

  /**
   * @param {[AppHomePeriodScheduleDomainModel]} value
   */
  set schedulesFromInteractor (value) {
    this._viewModel.bodySchedules.periodSchedulesCards = value.map(e => {
      // Sort days schedules
      const sortedSchedules = e.schedules.sort((a, b) => {
        return a.index > b.index ? 1 : -1
      })

      // Get day times
      const dayTimes = sortedSchedules.map(schedules => {
        const dayTime = new AppSchedulesDayCardTimeViewModel()
        dayTime.day = schedules.name
        dayTime.times = schedules.times.join(String.htmlLinebreak)

        return dayTime
      })

      // Cards for periods
      const card = new AppSchedulesDayCardViewModel()
      card.title = e.period
      card.dayTimes = dayTimes

      return card
    })
  }

  prepareHomeErrorFromInteractor () {
    this._showPopup(AppPopupType.ERROR_HOME, true)
  }

  prepareContactErrorFromInteractor () {
    this._showPopup(AppPopupType.ERROR_CONTACT, true)
  }

  prepareContactSuccessFromInteractor () {
    this._showPopup(AppPopupType.SUCCESS_CONTACT, false)
  }

  /** @param {boolean} value */
  set enableContactFormFromInteractor (value) {
    this._viewModel.bodyContact.formNameTextField.disabled = !value
    this._viewModel.bodyContact.formEmailTextField.disabled = !value
    this._viewModel.bodyContact.formMessageTextArea.disabled = !value
  }

  /** @param {boolean} value */
  set showContactLoaderFromInteractor (value) {
    this._viewModel.bodyContact.formSendButton.isLoading = value
  }

  showContactTextFieldsErrorFromInteractor () {
    this._validation.contactFormName.$touch()
    this._validation.contactFormEmail.$touch()
    this._validation.contactFormMessage.$touch()
  }

  clearContactTextFieldsFromInteractor () {
    // Remove texts
    this._validation.contactFormName.$model = String.empty
    this._validation.contactFormEmail.$model = String.empty
    this._validation.contactFormMessage.$model = String.empty

    // Clear rules
    this._validation.$reset()
  }

  // MARK: - Other

  /**
   * Update tabs and navigation selected index
   * @param {int} value corresponding to the new selected index
   */
  _updateAnchorSelectedIndex (value) {
    this._viewModel.appBar.tabs.selectedIndex = value
    this._viewModel.navigationMenu.listSelectedIndex = value
  }

  /**
   * Show popup after error or success
   * @param {string} messageKey message to show
   * @param {boolean} isError
   */
  _showPopup (messageKey, isError) {
    this._viewModel.popup.text = Localization.getText(messageKey)
    this._viewModel.popup.show = true
    this._viewModel.popup.color = isError ? Color.red : Color.green
  }
}
