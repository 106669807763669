<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Timeline -->
      <custom-timeline :view-model="viewModel.timeline">

        <!-- Item -->
        <custom-timeline-item
            v-for="item in viewModel.timeline.items"
            :key="item.key"
            :view-model="item"
        />
      </custom-timeline>
    </custom-col>
  </custom-row>
</template>

<script>
import AppWaylfViewModel from '@/presentation/scene/app/view-model/appWaylfViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import TimelineComponent from '@/app/common/component/timeline/TimelineComponent'
import WaylfItemComponent from '@/app/scene/app/component/waylf/AppWaylfTimelineItemComponent'

export default {
  name: 'AppWaylfComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customTimeline: TimelineComponent,
    customTimelineItem: WaylfItemComponent
  },

  props: {
    viewModel: {
      type: AppWaylfViewModel,
      required: true
    }
  }
}
</script>
