import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import Color from '@/presentation/utils/color'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import RatingViewModel from '@/presentation/view-model/rating/ratingViewModel'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.OPINION

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 8
    }

    static Card = {
      isStyleForMobile: true,
      classes: 'pa-sm-12 ' + Color.grey
    }

    static Title = {
      key: 'opinion_title',
      boldKey: 'opinion_title_bold'
    }

    static SubContent = {
      classes: 'text-md-center'
    }

    static Space = {
      content: PresentationSpace.CLASSIC
    }

    static Text = {
      key: 'opinion_description',
      classes: ' font-italic'
    }

    static ReadButton = {
      classes: 'mr-2 mb-2 ' + Color.textColor(Color.black),
      title: 'opinion_read_all'
    }
}

// MARK: - Class

export default class AppOpinionViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {CardViewModel} */
    _contentCard
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _subContentClasses
    /** @type {RatingViewModel} */
    _rating
    /** @type {SpaceViewModel} */
    _ratingBottomSpace
    /** @type {string} */
    _text
    /** @type {string} */
    _textClasses
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {TextButtonViewModel} */
    _readButton

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._contentCard = new CardViewModel()
      this._contentCard.isStyleForMobile = Constants.Card.isStyleForMobile
      this._contentCard.classes = Constants.Card.classes

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._subContentClasses = Constants.SubContent.classes

      this._rating = new RatingViewModel()

      this._ratingBottomSpace = new SpaceViewModel()
      this._ratingBottomSpace.space = Constants.Space.content

      this._text = Localization.getText(Constants.Text.key)
      this._textClasses = Constants.Text.classes

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.content

      this._readButton = new TextButtonViewModel()
      this._readButton.title = Localization.getText(Constants.ReadButton.title)
      this._readButton.classes = Constants.ReadButton.classes
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {CardViewModel} */
    get contentCard () {
      return this._contentCard
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get subContentClasses () {
      return this._subContentClasses
    }

    /** @return {RatingViewModel} */
    get rating () {
      return this._rating
    }

    /** @return {SpaceViewModel} */
    get ratingBottomSpace () {
      return this._ratingBottomSpace
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {string} */
    get textClasses () {
      return this._textClasses
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {TextButtonViewModel} */
    get readButton () {
      return this._readButton
    }
}
