import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PricesOffer from '@/presentation/scene/app/enum/appPricesOffer'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import PresentationSpace from '@/presentation/enum/presentationSpace'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.PRICES

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 8
    }

    static PriceItemRow = {
      justify: PresentationJustify.CENTER
    }

    static PriceItemCol = {
      cols: 12,
      sm: 6,
      lg: 3
    }

    static Title = {
      isCentered: false,
      key: 'prices_title',
      boldKey: 'prices_title_bold'
    }

    static offerCards = PricesOffer.all()

    static Space = {
      offerCards: PresentationSpace.MEDIUM
    }

    static TrialText = {
      key: 'prices_trial_description',
      style1Key: 'prices_trial_description_style_1',
      style2Bold1: 'prices_trial_description_style_2_bold_1',
      style2Bold2: 'prices_trial_description_style_2_bold_2',
      style2Color: null
    }

    static trialClasses = 'text-h6'
}

// MARK: - Class

export default class AppPricesViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {RowViewModel} */
    _itemRow
    /** @type {ColViewModel} */
    _itemCol
    /** @type {[AppPricesOfferCardViewModel]} */
    _offerCards
    /** @type {SpaceViewModel} */
    _offerCardsBottomSpace
    /** @type {string} */
    _trialText
    /** @type {string} */
    _trialClasses

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._itemRow = new RowViewModel()
      this._itemRow.justify = Constants.PriceItemRow.justify

      this._itemCol = new ColViewModel()
      this._itemCol.cols = Constants.PriceItemCol.cols
      this._itemCol.smAndGreater = Constants.PriceItemCol.sm
      this._itemCol.mdAndGreater = Constants.PriceItemCol.md
      this._itemCol.lgAndGreater = Constants.PriceItemCol.lg

      this._offerCards = Constants.offerCards.map(e => {
        return PricesOffer.card(e)
      })

      this._offerCardsBottomSpace = new SpaceViewModel()
      this._offerCardsBottomSpace.space = Constants.Space.offerCards

      const trialText = Localization.getText(Constants.TrialText.key)
      const trialTextStyles = [
        new Style(Constants.TrialText.style1Key),
        new Style(Constants.TrialText.style2Bold1, Constants.TrialText.style2Color),
        new Style(Constants.TrialText.style2Bold2, Constants.TrialText.style2Color)
      ]
      this._trialText = Style.addStylesOnText(trialText, trialTextStyles)

      this._trialClasses = Constants.trialClasses
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {RowViewModel} */
    get itemRow () {
      return this._itemRow
    }

    /** @return {ColViewModel} */
    get itemCol () {
      return this._itemCol
    }

    /** @return {[AppPricesOfferCardViewModel]} */
    get offerCards () {
      return this._offerCards
    }

    /** @return {SpaceViewModel} */
    get offerCardsBottomSpace () {
      return this._offerCardsBottomSpace
    }

    /** @return {string} */
    get trialText () {
      return this._trialText
    }

    /** @return {string} */
    get trialClasses () {
      return this._trialClasses
    }
}
