<template>
  <custom-card :view-model="viewModel">

    <!-- Title -->
    <v-card-title>
      {{ viewModel.title }}
    </v-card-title>

    <!-- Description -->
    <v-card-subtitle>
      {{ viewModel.description }}
    </v-card-subtitle>

    <custom-row :view-model="viewModel.contentRow">
      <custom-col
          v-for="(item, i) in viewModel.dayTimes"
          :key="i"
          :view-model="viewModel.contentCol"
      >
            <!-- Day -->
            <v-card-text :class="item.dayClasses">
              {{ item.day }}
            </v-card-text>

            <!-- Time -->
            <v-card-text :class="item.timesClasses" v-html="item.times">
              {{ item.times }}
            </v-card-text>

      </custom-col>
    </custom-row>
  </custom-card>
</template>

<script>
import AppSchedulesDayCardViewModel from '@/presentation/scene/app/view-model/schedules/appSchedulesDayCardViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'

export default {
  name: 'AppSchedulesDayCardComponent',

  components: {
    customCard: CardComponent,
    customRow: RowComponent,
    customCol: ColComponent
  },

  props: {
    viewModel: {
      type: AppSchedulesDayCardViewModel,
      required: true
    }
  },

  data: () => ({
    numbers: [1, 2, 3, 4, 5, 6, 7]
  })
}
</script>
