import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import Color from '@/presentation/utils/color'
import ChipGroupViewModel from '@/presentation/view-model/chip/chipGroupViewModel'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
    static classes = 'mx-auto ' + Color.grey

    static Image = {
      height: '250',
      contain: false
    }

    static Price = {
      classes: 'text-h5 font-weight-bold ' + Color.textColor(Color.main),
      suffix: '_value'
    }
}

// MARK: - Class

export default class AppPricesOfferCardViewModel extends CardViewModel {
  // MARK: - Properties

    /** @type {ImageViewModel|null} */
    _image
    /** @type {string|null} */
    _title
    /** @type {string|null} */
    _description
    /** @type {ChipGroupViewModel} */
    _offers
    /** @type {string|null} */
    _priceClasses
    /** @type {string|null} */
    _pricePrefixValue
    /** @type {string|null} */
    _priceValue

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._classes = Constants.classes

      this._image = null

      this._title = null
      this._description = null

      this._offers = new ChipGroupViewModel()

      this._priceClasses = Constants.Price.classes
      this._pricePrefixValue = null
      this._priceValue = null
    }

    // MARK: - Getter

    /** @return {ImageViewModel|null} */
    get image () {
      return this._image
    }

    /** @return {string|null} */
    get title () {
      return this._title
    }

    /** @return {string|null} */
    get description () {
      return this._description
    }

    /** @return {ChipGroupViewModel} */
    get offers () {
      return this._offers
    }

    /** @return {string} */
    get priceClasses () {
      return this._priceClasses
    }

    /** @return {string|null} */
    get priceValue () {
      return Localization.getText(this._pricePrefixValue + this.offers.selectedValue + Constants.Price.suffix)
    }

    // MARK: - Setter

    /** @param {ImageViewModel|null} value */
    set image (value) {
      this._image = value
      this._image.height = Constants.Image.height
      this._image.contain = Constants.Image.contain
    }

    /** @param {string|null} value */
    set titleKey (value) {
      this._title = Localization.getText(value)
    }

    /** @param {string|null} value */
    set descriptionKey (value) {
      this._description = Localization.getText(value)
    }

    /** @param {[ChipViewModel]} value */
    set offersItems (value) {
      this._offers.items = value
    }

    /** @param {string|null} value */
    set pricePrefixValue (value) {
      this._pricePrefixValue = value
    }
}
