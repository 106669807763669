import String from '@/presentation/utils/string'
import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
  static keyPrefix = '$vuetify.'
}

// MARK: - Class

export default class Localization {
  // MARK: - Getter

  static getText (key, ...params) {
    // Check if key isn't empty
    if (!String.isEmpty(key)) {
      return AppManager.shared.vuetify.lang.t(Constants.keyPrefix + key, params)
    } else {
      return String.empty
    }
  }
}
