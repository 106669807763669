import AppHomePeriodScheduleDomainModel from '@/domain/scene/app/model/appHomePeriodScheduleDomainModel'

export default class AppHomeDomainModel {
  /** @type {[AppHomePeriodScheduleDomainModel]} */
  _periodSchedules

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppHomeDataModel} dataModel
   */
  constructor (dataModel) {
    this._periodSchedules = dataModel.periodSchedules.map(e => {
      return new AppHomePeriodScheduleDomainModel(e)
    })
  }

  // MARK: - Getter

  /** @return {[AppHomePeriodScheduleDomainModel]} */
  get periodSchedules () {
    return this._periodSchedules
  }
}
