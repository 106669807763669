// MARK: - Constants

class Constants {
    static fillDot = true
    static large = true
}

// MARK: - Class

export default class TimelineItemViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _key
    /** @type {string|null} */
    _color
    /** @type {string|null} */
    _icon
    /** @type {boolean} */
    _fillDot
    /** @type {boolean} */
    _large

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._key = null
      this._color = null
      this._icon = null
      this._fillDot = Constants.fillDot
      this._large = Constants.large
    }

    // MARK: - Getter

    /** @return {string} */
    get key () {
      return this._key
    }

    /** @return {string} */
    get color () {
      return this._color
    }

    /** @return {string|null} */
    get icon () {
      return this._icon
    }

    /** @return {boolean} */
    get fillDot () {
      return this._fillDot
    }

    /** @return {boolean} */
    get large () {
      return this._large
    }

    // MARK: - Setter

    /** @param {string} value */
    set color (value) {
      this._color = value
    }

    /** @param {string} value */
    set icon (value) {
      this._key = value
      this._icon = value
    }

    /** @param {boolean} value */
    set fillDot (value) {
      this._fillDot = value
    }

    /** @param {boolean} value */
    set large (value) {
      this._large = value
    }
}
