import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import Color from '@/presentation/utils/color'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.CONCEPT

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 7
    }

    static Title = {
      isCentered: false,
      key: 'concept_title',
      boldKey: 'concept_title_bold'
    }

    static Space = {
      content: PresentationSpace.CLASSIC
    }

    static Text = {
      key: 'concept_description',
      bold1Key: 'concept_description_style_1_bold_1',
      bold2Style2Key: 'concept_description_style_2_bold_1',
      bold3Style2Key: 'concept_description_style_2_bold_2',
      bold4Style2Key: 'concept_description_style_2_bold_3',
      bold5Style2Key: 'concept_description_style_2_bold_4',
      bold6Style2Key: 'concept_description_style_2_bold_5',
      bold7Style2Key: 'concept_description_style_2_bold_6',
      bold8Style2Key: 'concept_description_style_2_bold_7',
      bold9Style2Key: 'concept_description_style_2_bold_8',
      bold10Style2Key: 'concept_description_style_2_bold_9',
      bold11Style2Key: 'concept_description_style_2_bold_10',
      style2Color: null
    }

    static AssetButton = {
      classes: 'mr-2 mb-2 ' + Color.textColor(Color.black),
      photos: 'concept_show_photos',
      movies: 'concept_show_movies'
    }
}

// MARK: - Class

export default class AppConceptViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _text
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {RowViewModel} */
    _assetItemRow
    /** @type {[TextButtonViewModel]} */
    _assetButtons

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)
      this._title.isCentered = Constants.Title.isCentered

      let text = Localization.getText(Constants.Text.key)
      text = String.addHtmllineBreak(text)
      const textStyles = [
        new Style(Constants.Text.bold1Key),
        new Style(Constants.Text.bold2Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold3Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold4Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold5Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold6Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold7Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold8Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold9Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold10Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold11Style2Key, Constants.Text.style2Color)
      ]
      this._text = Style.addStylesOnText(text, textStyles)

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.content

      this._assetItemRow = new RowViewModel()

      const assetsButtonTitles = [Constants.AssetButton.photos, Constants.AssetButton.movies]
      this._assetButtons = assetsButtonTitles.map(e => {
        const assetButton = new TextButtonViewModel()
        assetButton.title = Localization.getText(e)
        assetButton.classes = Constants.AssetButton.classes
        return assetButton
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {RowViewModel} */
    get assetItemRow () {
      return this._assetItemRow
    }

    /** @return {[TextButtonViewModel]} */
    get assetButtons () {
      return this._assetButtons
    }
}
