// MARK: - Constants

class Constants {
  static ContentType = {
    key: 'Content-Type',
    value: 'application/json'
  }
}

// MARK: - Class

export default class ApiConfiguration {
  // MARK: - Getter

  /** @type {[string]} */
  get _headers () {
    const headers = {}
    headers[Constants.ContentType.key] = Constants.ContentType.value
    return headers
  }

  /**
   * @return {AxiosRequestConfig}
   */
  config () {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      headers: this._headers
    }
  }
}
