export default class SpaceViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _classes

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._classes = null
    }

    // MARK: - Getter

    /** @return {string} */
    get classes () {
      return this._classes
    }

    // MARK: - Setter

    /** @param {string} value space (corresponds to PresentationSpace) */
    set space (value) {
      this._classes = value
    }
}
