import AppHomeContactDataEntry from '@/data/repository/app/appHomeContactDataEntry'

export default class AppHomeContactDomainEntry {
  // MARK: - Properties

    /** @type {string} */
    _name
    /** @type {string} */
    _message
    /** @type {string} */
    _email

    // MARK: - Constructor

    /**
     * @constructor
     * @param {any} validation from vuelidate which contains textfields
     */
    constructor (validation) {
      this._name = validation.contactFormName.$model
      this._email = validation.contactFormEmail.$model
      this._message = validation.contactFormMessage.$model
    }

    // MARK: - Conversion

    /**
     * Convert object to Data entry model
     * @return {AppHomeContactDataEntry}
     */
    get toData () {
      return new AppHomeContactDataEntry(this._name,
        this._message,
        this._email
      )
    }
}
