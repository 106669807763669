// MARK: - Constants

import Color from '@/presentation/utils/color'

class Constants {
    static color = Color.main
    static block = false
    static onlyText = false
    static isLoading = false
}

// MARK: - Class

export default class TextButtonViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _title
    /** @type {string|null} */
    _classes
    /** @type {string} */
    _color
    /** @type {boolean} */
    _block
    /** @type {boolean} */
    _onlyText
    /** @type {boolean} */
    _isLoading

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._title = null
      this._classes = null
      this._color = Constants.color
      this._block = Constants.block
      this._onlyText = Constants.onlyText
      this._isLoading = Constants.isLoading
    }

    // MARK: - Getter

    /** @return {string} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {string} */
    get color () {
      return this._color
    }

    /** @return {boolean} */
    get block () {
      return this._block
    }

    /** @return {boolean} */
    get onlyText () {
      return this._onlyText
    }

    /** @return {boolean} */
    get isLoading () {
      return this._isLoading
    }

    // MARK: - Setter

    /** @param {string} value */
    set title (value) {
      this._title = value
    }

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }

    /** @param {string} value */
    set color (value) {
      this._color = value
    }

    /** @param {boolean} value */
    set block (value) {
      this._block = value
    }

    /** @param {boolean} value */
    set onlyText (value) {
      this._onlyText = value
    }

    /** @param {boolean} value */
    set isLoading (value) {
      this._isLoading = value
    }
}
