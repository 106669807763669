import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import Localization from '@/presentation/utils/localization'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static defaultShow = false
  static defaultColor = Color.red
  static textClasses = Color.textColor(Color.black)
  static timeout = -1

  static CloseButton = {
    titleKey: 'popup_close',
    onlyText: true,
    color: Color.black
  }
}

// MARK: - Class

export default class PopupViewModel {
  // MARK: - Properties

    /** @type {boolean} */
    _show
    /** @type {string} */
    _color
    /** @type {number|string} */
    _timeout
    /** @type {string|null} */
    _text
    /** @type {string|null} */
    _textClasses
    /** @type {TextButtonViewModel} */
    _closeButton

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._show = Constants.defaultShow
      this._color = Constants.defaultColor
      this._timeout = Constants.timeout
      this._text = null
      this._textClasses = Constants.textClasses

      this._closeButton = new TextButtonViewModel()
      this._closeButton.title = Localization.getText(Constants.CloseButton.titleKey)
      this._closeButton.onlyText = Constants.CloseButton.onlyText
      this._closeButton.color = Constants.CloseButton.color
    }

    // MARK: - Getter

    /** @return {boolean} */
    get show () {
      return this._show
    }

    /** @return {string} */
    get color () {
      return this._color
    }

    /** @return {number|string} */
    get timeout () {
      return this._timeout
    }

    /** @return {string|null} */
    get text () {
      return this._text
    }

    /** @return {string|null} */
    get textClasses () {
      return this._textClasses
    }

    /** @return {TextButtonViewModel} */
    get closeButton () {
      return this._closeButton
    }

    // MARK: - Setter

    /** @param {boolean} value */
    set show (value) {
      this._show = value
    }

    /** @param {string} value */
    set color (value) {
      this._color = value
    }

    /** @param {string} value */
    set text (value) {
      this._text = value
    }
}
