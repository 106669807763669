import { required } from 'vuelidate/lib/validators'
import Localization from '@/presentation/utils/localization'
import TextAreaViewModel from '@/presentation/view-model/textarea/textAreaViewModel'

// MARK: - Constants

class Constants {
  static labelKey = 'contact_message'
  static required = true
  static type = 'text'
  static requiredKey = 'contact_message_required'
}

// MARK: - Class

export default class AppContactMessageTextAreaViewModel extends TextAreaViewModel {
  // MARK: - Properties

  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation
   */
  constructor (validation) {
    super(Localization.getText(Constants.labelKey),
      Constants.required,
      Constants.type
    )

    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      contactFormMessage: {
        required
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.contactFormMessage.$dirty) return errors
    !this._validation.contactFormMessage.required && errors.push(Localization.getText(Constants.requiredKey))
    return errors
  }
}
