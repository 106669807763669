// MARK: - Constants

class Constants {
    static linebreak = '\n'
}

// MARK: - Class

export default class String {
  // MARK: - Properties

    /** @type {string} */
    static linebreak = Constants.linebreak
}
