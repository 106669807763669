<template>
  <v-row
    :dense="viewModel.dense"
    :class="viewModel.classes"
    :justify="viewModel.justify"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-row>
</template>

<script>
import RowViewModel from '@/presentation/view-model/row/rowViewModel'

export default {
  name: 'RowComponent',

  props: {
    viewModel: {
      type: RowViewModel,
      required: true
    }
  }
}
</script>
