import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import String from '@/presentation/utils/string'
import Icon from '@/presentation/utils/icon'
import IconButtonViewModel from '@/presentation/view-model/button/iconButtonViewModel'
import IconViewModel from '@/presentation/view-model/icon/iconViewModel'
import WawCoach from '@/presentation/scene/app/enum/appWawCoach'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.WAW

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 7
    }

    static CoachItemRow = {
      justify: PresentationJustify.CENTER
    }

    static CoachItemCol = {
      cols: 6,
      sm: 3
    }

    static Title = {
      isCentered: false,
      key: 'waw_title',
      boldKey: 'waw_title_bold'
    }

    static Space = {
      content: PresentationSpace.MEDIUM,
      text: PresentationSpace.LITTLE
    }

    static Text = {
      key: 'waw_description',
      bold1Key: 'waw_description_style_1_bold_1',
      bold2Style2Key: 'waw_description_style_2_bold_1',
      bold3Style2Key: 'waw_description_style_2_bold_2',
      bold4Style2Key: 'waw_description_style_2_bold_3',
      style2Color: null
    }

    static coachCards = WawCoach.all()

    static SocialText = {
      key: 'waw_follow_us',
      bold1Key: 'waw_follow_us_style_1',
      bold2Style2Key: 'waw_follow_us_style_2',
      style2Color: null
    }

    static SocialButtons = {
      icons: [
        Icon.SOCIAL_FACEBOOK,
        Icon.SOCIAL_INSTAGRAM
      ],
      classes: 'ml-2 mr-4',
      size: 42
    }
}

// MARK: - Class

export default class AppWawViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {RowViewModel} */
    _coachItemRow
    /** @type {ColViewModel} */
    _coachItemCol
    /** @type {[AppWawCoachCardViewModel]} */
    _coachCards
    /** @type {SpaceViewModel} */
    _coachItemBottomSpace
    /** @type {string} */
    _text
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {string} */
    _socialText
    /** @type {RowViewModel} */
    _socialRow
    /** @type {[IconButtonViewModel]} */
    _socialIconButtons

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._coachItemRow = new RowViewModel()
      this._coachItemRow.justify = Constants.CoachItemRow.justify

      this._coachItemCol = new ColViewModel()
      this._coachItemCol.cols = Constants.CoachItemCol.cols
      this._coachItemCol.smAndGreater = Constants.CoachItemCol.sm

      this._coachCards = Constants.coachCards.map(e => {
        return WawCoach.card(e)
      })

      this._coachItemBottomSpace = new SpaceViewModel()
      this._coachItemBottomSpace.space = Constants.Space.content

      let text = Localization.getText(Constants.Text.key)
      text = String.addHtmllineBreak(text)
      const textStyles = [
        new Style(Constants.Text.bold1Key),
        new Style(Constants.Text.bold2Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold3Style2Key, Constants.Text.style2Color),
        new Style(Constants.Text.bold4Style2Key, Constants.Text.style2Color)
      ]
      this._text = Style.addStylesOnText(text, textStyles)

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.text

      const socialText = Localization.getText(Constants.SocialText.key)
      const socialTextStyles = [
        new Style(Constants.SocialText.bold1Key),
        new Style(Constants.SocialText.bold2Style2Key, Constants.Text.style2Color)
      ]
      this._socialText = Style.addStylesOnText(socialText, socialTextStyles)

      this._socialRow = new RowViewModel()

      this._socialIconButtons = Constants.SocialButtons.icons.map(e => {
        const icon = new IconViewModel()
        icon.name = e
        icon.size = Constants.SocialButtons.size

        const button = new IconButtonViewModel()
        button.icon = icon
        button.classes = Constants.SocialButtons.classes
        return button
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {RowViewModel} */
    get coachItemRow () {
      return this._coachItemRow
    }

    /** @return {ColViewModel} */
    get coachItemCol () {
      return this._coachItemCol
    }

    /** @return {[AppWawCoachCardViewModel]} */
    get coachCards () {
      return this._coachCards
    }

    /** @return {SpaceViewModel} */
    get coachItemBottomSpace () {
      return this._coachItemBottomSpace
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {string} */
    get socialText () {
      return this._socialText
    }

    /** @return {RowViewModel} */
    get socialRow () {
      return this._socialRow
    }

    /** @return {[IconButtonViewModel]} */
    get socialIconButtons () {
      return this._socialIconButtons
    }
}
