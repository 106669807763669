<template>
  <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="web/pictos/cardiology" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(0.000000, 7.000000)" fill-rule="nonzero">
        <path d="M50,86.3248047 C47.9673828,86.3248047 46.0255859,85.5658203 44.5328125,84.1875 L9.6390625,51.9699219 C3.51328125,46.3140625 0,38.2908203 0,29.9574219 C0,13.440625 13.4375,0.0033203125 29.9541016,0.0033203125 C37.3751953,0.0033203125 44.5263672,2.77792969 50,7.70195312 C55.4734375,2.77792969 62.6248047,0.0033203125 70.0458984,0.0033203125 C86.5625,0.0033203125 100,13.440625 100,29.9574219 C100,38.2908203 96.4867187,46.3142578 90.3609375,51.9701172 L55.6535156,84.0097656 C54.1365234,85.503125 52.1314453,86.3248047 50,86.3248047 Z" id="Shape" fill="#FFFFFF"></path>
        <path d="M70.0458984,0.0033203125 C62.6248047,0.0033203125 55.4736328,2.77792969 50,7.70195312 L50,86.3248047 C52.1314453,86.3248047 54.1365234,85.503125 55.6535156,84.0095703 L90.3609375,51.9699219 C96.4867187,46.3140625 100,38.2908203 100,29.9574219 C100,13.440625 86.5625,0.0033203125 70.0458984,0.0033203125 Z" id="Shape" fill="#E9E9E9"></path>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Waylf1Icon'
})
</script>
