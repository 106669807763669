import Vue from 'vue'

export const Bus = new Vue()

export class Events {
    static clicked = 'click'

    static menuClicked = 'menuClick'
    static navigationClicked = 'navigationClick'
    static closeNavigationClicked = 'closeNavigationClick'
    static accountClicked = 'accountClick'

    static assetClicked = 'assetClick'
    static socialClicked = 'socialClick'
    static readOpinionClicked = 'readOpinionClick'
    static storeClicked = 'storeClick'
    static mapClicked = 'mapClick'
    static contactLinkClicked = 'contactLinkClick'
    static contactSendClicked = 'contactSendClick'

    static scroll = 'scroll'
    static resize = 'resize'
}
