<template>
  <v-layout
      :justify-center="viewModel.justifyCenter"
      :align-center="viewModel.alignCenter"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-layout>
</template>

<script>
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'

export default {
  name: 'LayoutComponent',

  props: {
    viewModel: {
      type: LayoutViewModel,
      required: true
    }
  }
}
</script>
