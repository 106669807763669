<template>
  <v-snackbar
      v-model="viewModel.show"
      :color="viewModel.color"
      :timeout="viewModel.timeout">

    <div :class="viewModel.textClasses">
      {{ viewModel.text }}
    </div>

    <template v-slot:action="{ attrs }">
      <custom-button
          :view-model="viewModel.closeButton"
          v-bind="attrs"
          @click="viewModel.show = false"
      />
    </template>
  </v-snackbar>
</template>

<script>
import PopupViewModel from '@/presentation/view-model/popup/popupViewModel'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'

export default {
  name: 'PopupComponent',

  props: {
    viewModel: {
      type: PopupViewModel,
      required: true
    }
  },

  components: {
    customButton: TextButtonComponent
  }
}
</script>
