<template>
  <custom-timeline-item :view-model="viewModel">

    <!-- Card -->
    <v-sheet
        :class="viewModel.cardClasses"
        :elevation="viewModel.cardElevation">

        <!-- Title -->
        <h2 :class="viewModel.titleClasses">
          {{ viewModel.title }}
        </h2>

        <!-- Description -->
        <p v-html="viewModel.description"/>
    </v-sheet>
  </custom-timeline-item>
</template>

<script>
import AppWaylfTimelineItemViewModel from '@/presentation/scene/app/view-model/waylf/appWaylfTimelineItemViewModel'
import TimelineItemComponent from '@/app/common/component/timeline/TimelineItemComponent'

export default {
  name: 'AppWaylfItemComponent',

  components: {
    customTimelineItem: TimelineItemComponent
  },

  props: {
    viewModel: {
      type: AppWaylfTimelineItemViewModel,
      required: true
    }
  }
}
</script>
