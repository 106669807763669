import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static dense = true
}

// MARK: - Class

export default class RowViewModel {
  // MARK: - Properties

  /** @type {string} */
  _classes
  /** @type {boolean} */
  _dense
  /** @type {string|null} */
  _justify

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._classes = String.empty
    this._dense = Constants.dense
    this._justify = null
  }

  // MARK: - Getter

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get dense () {
    return this._dense
  }

  /** @return {string} */
  get justify () {
    return this._justify
  }

  // MARK: - Setter

  /** @param {string} value */
  set classes (value) {
    this._classes = value
  }

  /** @param {boolean} value */
  set dense (value) {
    this._dense = value
  }

  /** @param {string} value */
  set justify (value) {
    this._justify = value
  }
}
