import String from '@/presentation/utils/string'

// MARK: - Constants

const NONE = String.empty
const TINY = 'tiny-space'
const LITTLE = 'little-space'
const CLASSIC = 'space'
const MEDIUM = 'medium-space'
const BIG = 'big-space'

// MARK: - Enum

export default {
  NONE,
  TINY,
  LITTLE,
  CLASSIC,
  MEDIUM,
  BIG
}
