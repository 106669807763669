import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static defaultContain = true
}

// MARK: - Class

export default class ImageViewModel {
  // MARK: - Properties

    /** @type {string} */
    _src
    /** @type {string} */
    _lazySrc
    /** @type {boolean|null} */
    _contain
    /** @type {number|string|null} */
    _width
    /** @type {number|string|null} */
    _height
    /** @type {number|string|null} */
    _minHeight
    /** @type {string|null} */
    _style
    /** @type {string|null} */
    _classes

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._src = String.empty
      this._lazySrc = String.empty
      this._classes = null
      this._width = null
      this._height = null
      this._minHeight = null
      this._style = null
      this._contain = Constants.defaultContain
    }

    // MARK: - Getter

    /** @return {string} */
    get src () {
      return this._src
    }

    /** @return {string} */
    get lazySrc () {
      return this._lazySrc
    }

    /** @return {boolean} */
    get contain () {
      return this._contain
    }

    /** @return {number|string|null} */
    get width () {
      return this._width
    }

    /** @return {number|string|null} */
    get height () {
      return this._height
    }

    /** @return {number|string|null} */
    get minHeight () {
      return this._minHeight
    }

    /** @return {string|null} */
    get style () {
      return this._style
    }

    /** @return {string|null} */
    get classes () {
      return this._classes
    }

    // MARK: - Setter

    /** @param {string} value */
    set src (value) {
      this._src = value
    }

    /** @param {string} value */
    set lazySrc (value) {
      this._lazySrc = value
    }

    /** @param {boolean} value */
    set contain (value) {
      this._contain = value
    }

    /** @param {number} value */
    set width (value) {
      this._width = value
    }

    /** @param {number} value */
    set height (value) {
      this._height = value
    }

    /** @param {number|string} value */
    set minHeight (value) {
      this._minHeight = value
    }

    /** @param {string} value */
    set style (value) {
      this._style = value
    }

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }
}
