export default class AppHomeScheduleDataModel {
    /** @type {int} */
    _index
    /** @type {string} */
    _name
    /** @type {[string]} */
    _times

    // MARK: - Constructor

    /**
     * @constructor
     * @param {any} response corresponding to ArrayAPIResponse.schedules[x]
     */
    constructor (response) {
      this._index = response.index
      this._name = response.name
      this._times = response.values
    }

    // MARK: - Getter

    /** @return {int} */
    get index () {
      return this._index
    }

    /** @return {string} */
    get name () {
      return this._name
    }

    /** @return {[string]} */
    get times () {
      return this._times
    }
}
