<template>
    <v-img
        :src="viewModel.src"
        :lazy-src="viewModel.lazySrc"
        :contain="viewModel.contain"
        :width="viewModel.width"
        :height="viewModel.height"
        :min-height="viewModel.minHeight"
        :style="viewModel.style"
        :class="viewModel.classes"
        @click="clicked()"
    >
      {{ viewModel.name }}
    </v-img>
</template>

<script>
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'ImageComponent',

  props: {
    viewModel: {
      type: ImageViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'image-style.scss'
</style>
