import ApiViolationResponseErrorModel from '@/data/api/response-error/model/apiViolationResponseErrorModel'

// MARK: - Constants

class Constants {
  static internal = 'internal'
}

// MARK: - Class

export default class ApiResponseErrorModel {
  // MARK: - Properties

  /** @type {string|null} */
  _type
  /** @type {string|null} */
  _title
  /** @type {string|null} */
  _detail
  /** @type {[ApiViolationResponseErrorModel]|null} */
  _violations

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._type = response.type
    this._title = response.title
    this._detail = response.detail
    if (response.violations) {
      this._violations = response.violations.map(e => {
        return new ApiViolationResponseErrorModel(e)
      })
    }
  }

  // MARK: - Getter

  /** @return {boolean} */
  get isValid () {
    return this._type && this._title && this._detail && !this.detail.toLowerCase().includes(Constants.internal)
  }

  /** @return {string} */
  get type () {
    return this._type
  }

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get detail () {
    return this._detail
  }

  /** @return {[ApiViolationResponseErrorModel]|null} */
  get violations () {
    return this._violations
  }
}
