import AppHomeScheduleDomainModel from '@/domain/scene/app/model/appHomeScheduleDomainModel'

export default class AppHomePeriodScheduleDomainModel {
    /** @type {string} */
    _period
    /** @type {[AppHomeScheduleDomainModel]} */
    _schedules

    // MARK: - Constructor

    /**
     * @constructor
     * @param {AppHomePeriodScheduleDataModel} dataModel
     */
    constructor (dataModel) {
      this._period = dataModel.period
      this._schedules = dataModel.values.map(e => {
        return new AppHomeScheduleDomainModel(e)
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get period () {
      return this._period
    }

    /** @return {[AppHomeScheduleDomainModel]} */
    get schedules () {
      return this._schedules
    }
}
