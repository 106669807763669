<template>
  <v-app-bar
    :absolute="viewModel.absolute"
    :style="viewModel.style"
    :color="viewModel.color"
    :dark="viewModel.dark"
    :shrink-on-scroll="viewModel.shrinkOnScroll"
    :prominent="viewModel.prominent"
    :elevate-on-scroll="viewModel.elevateOnScroll"
    :scroll-target="viewModel.scrollTarget"
  >

    <!-- Burger icon button -->
    <v-app-bar-nav-icon
        :color="viewModel.burgerIconColor"
        @click="menuClicked()"
    />

    <!-- Left space -->
    <custom-space :view-model="viewModel.leftSpace"/>

    <!-- Logo -->
    <custom-icon :view-model="viewModel.logoIcon"/>

    <!-- Right space -->
    <custom-space :view-model="viewModel.rightSpace"/>

    <!-- Account button -->
    <custom-icon-button
      :view-model="viewModel.accountButton"
      @click="accountClicked()"
    />

    <!-- Tabs -->
    <template v-slot:extension v-if="viewModel.showTabs">
      <custom-tabs
          :view-model="viewModel.tabs"
          @click="navigationClicked($event)"
      />
    </template>
  </v-app-bar>
</template>

<script>
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import IconComponent from '@/app/common/component/icon/IconComponent'
import IconButtonComponent from '@/app/common/component/button/IconButtonComponent'
import TabsComponent from '@/app/common/component/tabs/TabsComponent'

import AppBarViewModel from '@/presentation/view-model/app-bar/appBarViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'AppBarComponent',

  components: {
    customSpace: SpaceComponent,
    customIcon: IconComponent,
    customIconButton: IconButtonComponent,
    customTabs: TabsComponent
  },

  props: {
    viewModel: {
      type: AppBarViewModel,
      required: true
    }
  },

  methods: {
    menuClicked () {
      this.$emit(Events.menuClicked)
    },
    navigationClicked (anchor) {
      this.$emit(Events.navigationClicked, anchor)
    },
    accountClicked () {
      this.$emit(Events.accountClicked)
    }
  }
}
</script>
