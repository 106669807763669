// MARK: - Constants

const CONCEPT = 0
const WAW = 1
const WAYLF = 2
const SCHEDULES = 3
const PRICES = 4
const OPINION = 5
const APP = 6
const LOCALITY = 7
const CONTACT = 8

// MARK: - Enum

export default {
  CONCEPT,
  WAW,
  WAYLF,
  SCHEDULES,
  PRICES,
  OPINION,
  APP,
  LOCALITY,
  CONTACT,

  /**
     * @return {[int]} return all enum values
     */
  all () {
    return [
      CONCEPT,
      WAW,
      WAYLF,
      SCHEDULES,
      PRICES,
      OPINION,
      APP,
      LOCALITY,
      CONTACT
    ]
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  nameKey (value) {
    switch (value) {
      case CONCEPT:
        return 'tab_concept'
      case WAW:
        return 'tab_waw'
      case WAYLF:
        return 'tab_waylf'
      case SCHEDULES:
        return 'tab_schedules'
      case PRICES:
        return 'tab_prices'
      case OPINION:
        return 'tab_opinion'
      case APP:
        return 'tab_app'
      case LOCALITY:
        return 'tab_locality'
      default:
        return 'tab_contact'
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  anchorKey (value) {
    switch (value) {
      case CONCEPT:
        return 'concept'
      case WAW:
        return 'waw'
      case WAYLF:
        return 'waylf'
      case SCHEDULES:
        return 'schedules'
      case PRICES:
        return 'prices'
      case OPINION:
        return 'opinion'
      case APP:
        return 'app'
      case LOCALITY:
        return 'locality'
      default:
        return 'contact'
    }
  },

  /**
     * @param {string} value corresponding to anchor key
     * @return {int}
     */
  value (value) {
    switch (value) {
      case this.anchorKey(CONCEPT):
        return CONCEPT
      case this.anchorKey(WAW):
        return WAW
      case this.anchorKey(WAYLF):
        return WAYLF
      case this.anchorKey(SCHEDULES):
        return SCHEDULES
      case this.anchorKey(PRICES):
        return PRICES
      case this.anchorKey(OPINION):
        return OPINION
      case this.anchorKey(APP):
        return APP
      case this.anchorKey(LOCALITY):
        return LOCALITY
      default:
        return CONTACT
    }
  }
}
