// MARK: - Constants

class Constants {
    static keyPrefix = '$'
}

const LOGO = Constants.keyPrefix + 'iconLogo'

const NAVIGATION_ACCOUNT = Constants.keyPrefix + 'iconNavigationAccount'

const SOCIAL_INSTAGRAM = Constants.keyPrefix + 'iconSocialInstagram'
const SOCIAL_FACEBOOK = Constants.keyPrefix + 'iconSocialFacebook'

const WAYLF_1 = Constants.keyPrefix + 'iconWaylf1'
const WAYLF_2 = Constants.keyPrefix + 'iconWaylf2'
const WAYLF_3 = Constants.keyPrefix + 'iconWaylf3'
const WAYLF_4 = Constants.keyPrefix + 'iconWaylf4'
const WAYLF_5 = Constants.keyPrefix + 'iconWaylf5'
const WAYLF_6 = Constants.keyPrefix + 'iconWaylf6'

// MARK: - Enum

export default {
  LOGO,
  NAVIGATION_ACCOUNT,
  SOCIAL_INSTAGRAM,
  SOCIAL_FACEBOOK,
  WAYLF_1,
  WAYLF_2,
  WAYLF_3,
  WAYLF_4,
  WAYLF_5,
  WAYLF_6
}
