import String from '@/presentation/utils/string'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static color = Color.main
  static outlined = true
  static placeholder = String.space
  static type = 'text'
  static autocomplete = 'off'
  static clearable = false
  static disabled = false
}

// MARK: - Class

export default class TextFieldViewModel {
  // MARK: - Properties

  /** @type {string} */
  _label
  /** @type {string} */
  _color
  /** @type {string} */
  _type
  /** @type {string} */
  _placeholder
  /** @type {boolean} */
  _outlined
  /** @type {boolean} */
  _required
  /** @type {string} */
  _autocomplete
  /** @type {boolean} */
  _clearable
  /** @type {boolean} */
  _disabled

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {boolean} required
   * @param {string} type
   * @param {string} autocomplete
   * @param {string} placeholder
   * @param {string} color
   * @param {boolean} outlined
   * @param {boolean} clearable
   * @param {boolean} disabled
   */
  constructor (label, required,
    type = Constants.type,
    autocomplete = Constants.autocomplete,
    placeholder = Constants.placeholder,
    color = Constants.color,
    outlined = Constants.outlined,
    clearable = Constants.clearable,
    disabled = Constants.disabled) {
    this._label = label
    this._color = color
    this._type = type
    this._placeholder = placeholder
    this._outlined = outlined
    this._required = required
    this._autocomplete = autocomplete
    this._clearable = clearable
    this._disabled = clearable
  }

  // MARK: - Getter

  /** @return {string} */
  get label () {
    return this._label
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {string} */
  get type () {
    return this._type
  }

  /** @return {string} */
  get placeholder () {
    return this._placeholder
  }

  /** @return {boolean} */
  get outlined () {
    return this._outlined
  }

  /** @return {boolean} */
  get required () {
    return this._required
  }

  /** @return {string} */
  get autocomplete () {
    return this._autocomplete
  }

  /** @return {boolean} */
  get clearable () {
    return this._clearable
  }

  /** @return {boolean} */
  get disabled () {
    return this._disabled
  }

  // MARK: - Setter

  /** @param {string} value */
  set label (value) {
    this._label = value
  }

  /** @param {string} value */
  set color (value) {
    this._color = value
  }

  /** @param {string} value */
  set type (value) {
    this._type = value
  }

  /** @param {string} value */
  set placeholder (value) {
    this._placeholder = value
  }

  /** @param {boolean} value */
  set outlined (value) {
    this._outlined = value
  }

  /** @param {boolean} value */
  set required (value) {
    this._required = value
  }

  /** @param {string} value */
  set autocomplete (value) {
    this._autocomplete = value
  }

  /** @param {boolean} value */
  set clearable (value) {
    this._clearable = value
  }

  /** @param {boolean} value */
  set disabled (value) {
    this._disabled = value
  }
}
