<template>
  <v-sheet
      :class="viewModel.classes"
      :width="viewModel.width"
      :height="viewModel.height"
  />
</template>

<script>
import AppSpaceBackgroundViewModel from '@/presentation/scene/app/view-model/appSpaceBackgroundViewModel'

export default {
  name: 'AppSpaceBackgroundComponent',

  props: {
    viewModel: {
      type: AppSpaceBackgroundViewModel,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'app-space-background.scss'
</style>
