<template>
  <!-- Card -->
  <custom-card :view-model="viewModel">

    <!-- Avatar -->
    <custom-layout :view-model="viewModel.avatarLayout">
      <v-avatar
          :class="viewModel.avatarClasses"
          :size="viewModel.avatarSize"
      >
        <custom-image :view-model="viewModel.avatar"/>
      </v-avatar>
    </custom-layout>

    <!-- Avatar space -->
    <custom-space :view-model="viewModel.avatarBottomSpace"/>

    <!-- Name -->
    <p :class="viewModel.nameClasses">
      {{ viewModel.name }}
    </p>

  </custom-card>
</template>

<script>
import AppWawCoachCardViewModel from '@/presentation/scene/app/view-model/waw/appWawCoachCardViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import ImageComponent from '@/app/common/component/image/ImageComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LayoutComponent from '@/app/common/component/layout/LayoutComponent'

export default {
  name: 'AppWawCardComponent',

  components: {
    customCard: CardComponent,
    customSpace: SpaceComponent,
    customLayout: LayoutComponent,
    customImage: ImageComponent
  },

  props: {
    viewModel: {
      type: AppWawCoachCardViewModel,
      required: true
    }
  }
}
</script>
