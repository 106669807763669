<template>
  <div>
    <!-- Image -->
    <custom-img :view-model="viewModel.headerImage"/>

    <!-- Image space -->
    <custom-space :view-model="viewModel.headerImageBottomSpace"/>

    <!-- Text -->
    <p :class="viewModel.textClasses">
      {{ viewModel.text }}
    </p>

    <!-- Asset space -->
    <custom-space :view-model="viewModel.textBottomSpace"/>

    <!-- Apps Store -->
    <custom-row :view-model="viewModel.storeRow">
      <custom-col :view-model="viewModel.storeCol">
        <custom-row :view-model="viewModel.storeItemRow">
          <custom-col
              v-for="(item, i) in viewModel.storeImages"
              :key="i"
              :view-model="viewModel.storeItemCol"
          >
            <custom-img
                :view-model="item"
                @click="storeClicked(i)"
            />
          </custom-col>
        </custom-row>
      </custom-col>
    </custom-row>
  </div>
</template>

<script>
import AppAppsViewModel from '@/presentation/scene/app/view-model/appAppsViewModel'
import ImageComponent from '@/app/common/component/image/ImageComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppAppsComponent',

  components: {
    customImg: ImageComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent
  },

  props: {
    viewModel: {
      type: AppAppsViewModel,
      required: true
    }
  },

  methods: {
    storeClicked (index) {
      this.$emit(Events.storeClicked, index)
    }
  }
}
</script>
