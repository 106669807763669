<template>
  <v-tabs
      v-model="viewModel.selectedIndex"
      :class="viewModel.classes"
      :color="viewModel.color"
      :grow="viewModel.grow"
      :light="viewModel.light"
  >

    <!-- Items -->
    <v-tab
        v-for="item in viewModel.items"
        :key="item.key"
        @click="clicked(item.anchor)"
    >
      {{ item.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
import TabsViewModel from '@/presentation/view-model/tabs/tabsViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'TabsComponent',

  props: {
    viewModel: {
      type: TabsViewModel,
      required: true
    }
  },

  methods: {
    clicked (anchor) {
      this.$emit(Events.clicked, anchor)
    }
  }
}
</script>
