// MARK: - Constants

class Constants {
    static justifyCenter = true
    static alignCenter = true
}

// MARK: - Class

export default class LayoutViewModel {
  // MARK: - Properties

    /** @type {boolean} */
    _justifyCenter
    /** @type {boolean} */
    _alignCenter

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._justifyCenter = Constants.justifyCenter
      this._alignCenter = Constants.alignCenter
    }

    // MARK: - Getter

    /** @return {boolean} */
    get justifyCenter () {
      return this._justifyCenter
    }

    /** @return {boolean} */
    get alignCenter () {
      return this._alignCenter
    }

    // MARK: - Setter

    /** @param {boolean} value */
    set justifyCenter (value) {
      this._justifyCenter = value
    }

    /** @param {boolean} value */
    set alignCenter (value) {
      this._alignCenter = value
    }
}
