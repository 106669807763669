<template>
  <v-chip-group
      v-model="viewModel.selectedValue"
      :active-class="viewModel.activeClasses"
      :column="viewModel.column"
  >

    <!-- Chip items -->
    <v-chip
        v-for="(item, i) in viewModel.items"
        :key="i"
        :value="item.value"
    >
      {{ item.text }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import ChipGroupViewModel from '@/presentation/view-model/chip/chipGroupViewModel'

export default {
  name: 'ChipGroupComponent',

  props: {
    viewModel: {
      type: ChipGroupViewModel,
      required: true
    }
  }
}
</script>
