import Axios from 'axios'
import ApiResponseError from '@/data/api/response-error/apiResponseError'

// MARK: - Class

export default class ApiWorker {
  // MARK: - Properties

  /** @type {ApiConfiguration} */
  _configuration

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ApiConfiguration} configuration
   */
  constructor (configuration) {
    this._configuration = configuration
  }

  // MARK: - Get

  /**
   * @param {string} url
   */
  getRequest (url) {
    const self = this
    return new Promise(function (resolve, reject) {
      Axios.get(url, self._configuration.config()).then(response => {
        resolve(response.data)
      }).catch(e => {
        const error = self._catchError(e)
        reject(error)
      })
    })
  }

  // MARK: - Post

  /**
   * @param {string} url
   * @param {any} parameter
   */
  postRequest (url, parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      Axios.post(url, parameter.body, self._configuration.config()).then(response => {
        resolve(response.data)
      }).catch(e => {
        const error = self._catchError(e)
        reject(error)
      })
    })
  }

  // MARK: - Error

  /**
   * @param {any} exception
   * @return ApiResponseError
   */
  _catchError (exception) {
    return new ApiResponseError(exception.response.status, exception.response.data)
  }
}
