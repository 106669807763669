// MARK: - Constants

const SCROLLING = 0

// MARK: - Enum

export default {
  SCROLLING,

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  value (value) {
    switch (value) {
      default:
        return 'scrolling-id'
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string} with #
     */
  ref (value) {
    return '#' + this.value(value)
  }
}
