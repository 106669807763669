<template>
  <v-timeline
      :dense="viewModel.dense"
  >
      <!-- Slot needed to put content here -->
      <slot></slot>
  </v-timeline>
</template>

<script>
import TimelineViewModel from '@/presentation/view-model/timeline/timelineViewModel'

export default {
  name: 'TimelineComponent',

  props: {
    viewModel: {
      type: TimelineViewModel,
      required: true
    }
  }
}
</script>
