import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import TimelineViewModel from '@/presentation/view-model/timeline/timelineViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Waylf from '@/presentation/scene/app/enum/appWaylf'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.WAYLF

    static StoreRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 7
    }

    static Title = {
      key: 'waylf_title',
      boldKey: 'waylf_title_bold'
    }

    static Space = {
      section: PresentationSpace.MEDIUM
    }

    static items = Waylf.all()
}

// MARK: - Class

export default class AppWaylfViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {TimelineViewModel} */
    _timeline

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.StoreRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._timeline = new TimelineViewModel()
      this._timeline.items = Constants.items.map(e => {
        return Waylf.item(e)
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {TimelineViewModel} */
    get timeline () {
      return this._timeline
    }
}
