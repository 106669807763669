import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import Color from '@/presentation/utils/color'
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'

// MARK: - Constants

class Constants {
    static classes = 'px-0 pt-0 pb-1 ' + Color.grey

    static Avatar = {
      classes: 'rounded',
      size: '100%'
    }

    static space = PresentationSpace.LITTLE
    static nameClasses = 'font-weight-bold text-center'
}

// MARK: - Class

export default class AppWawCoachCardViewModel extends CardViewModel {
  // MARK: - Properties

    /** @type {LayoutViewModel|null} */
    _avatarLayout
    /** @type {ImageViewModel|null} */
    _avatar
    /** @type {string|int|null} */
    _avatarClasses
    /** @type {string|int|null} */
    _avatarSize
    /** @type {SpaceViewModel} */
    _avatarBottomSpace
    /** @type {string|null} */
    _name
    /** @type {string|null} */
    _nameClasses

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._classes = Constants.classes

      this._avatarLayout = new LayoutViewModel()
      this._avatar = null
      this._avatarClasses = Constants.Avatar.classes
      this._avatarSize = Constants.Avatar.size

      this._avatarBottomSpace = new SpaceViewModel()
      this._avatarBottomSpace.space = Constants.space

      this._name = null
      this._nameClasses = Constants.nameClasses
    }

    // MARK: - Getter

    /** @return {LayoutViewModel|null} */
    get avatarLayout () {
      return this._avatarLayout
    }

    /** @return {ImageViewModel|null} */
    get avatar () {
      return this._avatar
    }

    /** @return {string|int|null} */
    get avatarClasses () {
      return this._avatarClasses
    }

    /** @return {string|int|null} */
    get avatarSize () {
      return this._avatarSize
    }

    /** @return {SpaceViewModel} */
    get avatarBottomSpace () {
      return this._avatarBottomSpace
    }

    /** @return {string|null} */
    get name () {
      return this._name
    }

    /** @return {string|null} */
    get nameClasses () {
      return this._nameClasses
    }

    // MARK: - Setter

    /** @param {ImageViewModel|null} value */
    set avatar (value) {
      this._avatar = value
    }

    /** @param {string|null} value */
    set name (value) {
      this._name = value
    }
}
