import Vue from 'vue'
import App from '@/app/scene/app/AppVue'
import vuetify from '@/app/plugins/vuetify'
import { validationMixin } from 'vuelidate'

import 'vuetify/dist/vuetify.min.css'
import AppManager from '@/common/manager/appManager'

Vue.config.productionTip = false

// Init vuetify manager
AppManager.shared = new AppManager(vuetify.framework)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  mixins: [validationMixin],
  components: { App },
  template: '<App/>'
})
