<template>
  <v-sheet
      :id="viewModel.id"
      :class="viewModel.classes"
      :fluid="viewModel.fluid"
      :style="viewModel.style"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-sheet>
</template>

<script>
import BodyViewModel from '@/presentation/view-model/body/bodyViewModel'

export default {
  name: 'BodyComponent',

  props: {
    viewModel: {
      type: BodyViewModel,
      required: true
    }
  }
}
</script>
