<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Text -->
      <p
          v-html="viewModel.text"
          :class="viewModel.textClasses"
      />

    </custom-col>
  </custom-row>
</template>

<script>
import AppCatchPhraseViewModel from '@/presentation/scene/app/view-model/appCatchPhraseViewModel'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'

export default {
  name: 'AppCatchPhraseComponent',

  components: {
    customRow: RowComponent,
    customCol: ColComponent
  },

  props: {
    viewModel: {
      type: AppCatchPhraseViewModel,
      required: true
    }
  }
}
</script>
