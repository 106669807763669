import String from '@/presentation/utils/string'
import Localization from '@/presentation/utils/localization'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
    static size = 'text-h4 text-md-h2 text-uppercase'

    static leftPosition = 'text-left'
    static centerPosition = 'text-md-center'

    static font = 'font-weight-regular'
    static boldFont = 'font-weight-black'

    static Space = {
      min: PresentationSpace.NONE,
      max: PresentationSpace.MEDIUM
    }
}

// MARK: - Class

export default class SectionTitleViewModel {
  // MARK: - Properties

    /** @type {string} */
    _classes
    /** @type {string} */
    _boldClasses
    /** @type {string} */
    _text
    /** @type {string} */
    _boldText
    /** @type {SpaceViewModel} */
    _space

    // MARK: - Constructor

    /**
     * @constructor
     * @param {Framework} vuetify
     */
    constructor () {
      this._classes = [Constants.size, Constants.centerPosition, Constants.font].join(String.space)
      this._boldClasses = [Constants.size, Constants.centerPosition, Constants.boldFont].join(String.space)
      this._text = String.empty
      this._boldText = String.empty

      this._space = new SpaceViewModel()
      this._space.space = Constants.space
    }

    // MARK: - Getter

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {string} */
    get boldClasses () {
      return this._boldClasses
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {string} */
    get boldText () {
      return this._boldText
    }

    /** @return {SpaceViewModel} */
    get space () {
      this._space.space = AppManager.shared.vuetify.breakpoint.smAndDown ? Constants.Space.min : Constants.Space.max
      return this._space
    }

    // MARK: - Setter

    /**
     * @param {boolean} value
     **/
    set isCentered (value) {
      this._classes = [Constants.size, Constants.leftPosition, Constants.font].join(String.space)
      this._boldClasses = [Constants.size, Constants.leftPosition, Constants.boldFont].join(String.space)
    }

    /**
     * @param {string} textKey
     * @param {string} boldKey
    **/
    setText (textKey, boldKey) {
      this._text = Localization.getText(textKey)
      this._boldText = Localization.getText(boldKey)
    }
}
