<template>
  <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="web/pictos/leaf" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(11.000000, 0.000000)" fill-rule="nonzero">
        <path d="M15.8847656,73.3900391 C13.9140625,72.0912109 12.0621094,70.5796875 10.3796875,68.8976563 C3.77226563,62.2902344 0.133398438,53.4998047 0.133398438,44.1458984 C0.133398438,20.2671875 23.7009766,6.18847656 42.6199219,1.97539063 C52.55625,-0.237109375 63.725,-0.592773437 75.8152344,0.918359375 C77.3505859,1.11035156 78.4720703,2.46308594 78.3757813,4.00742188 C78.2796875,5.55175781 76.9992188,6.75507813 75.4517578,6.75507813 C62.1408203,6.75527344 48.61875,14.8484375 39.3974609,24.4787109 C27.3802734,37.0291016 20.6427734,53.54375 20.4261719,70.9804688 C20.4128906,72.0515625 19.8160156,73.0300781 18.8697266,73.5320313 C17.9267578,74.0324219 16.7816406,73.98125 15.8847656,73.3900391 Z" id="Shape" fill="#FFFFFF"></path>
        <path d="M17.4914063,100 C15.8734375,100 14.5617188,98.6882812 14.5617188,97.0703125 L14.5617188,70.9400391 C14.5617188,69.3220703 15.8734375,68.0103516 17.4914063,68.0103516 C19.109375,68.0103516 20.4210938,69.3220703 20.4210938,70.9400391 L20.4210938,97.0703125 C20.4210938,98.6882812 19.109375,100 17.4914063,100 Z" id="Shape" fill="#FFFFFF"></path>
        <path d="M78.2660156,3.00976563 C77.903125,1.75722656 76.7560547,0.895507813 75.4521484,0.895507813 C61.9359375,0.895703125 46.8755859,8.196875 35.1658203,20.4261719 C22.1179688,34.0527344 14.8027344,51.9808594 14.5675781,70.9074219 C14.5675781,70.9136719 14.5679688,70.9199219 14.5679688,70.9261719 C14.5642578,70.934375 14.5617188,70.9398437 14.5617188,70.9398437 L14.5617188,72.4707031 C14.9964844,72.7873047 15.4371094,73.0947266 15.8847656,73.3898438 C29.4740234,82.3462891 47.9566406,80.8242188 59.8832031,68.8972656 C70.2019531,58.5785156 68.0615234,44.3617188 67.3564453,36.3830078 C66.2798828,24.2023438 65.3501953,13.6828125 77.0183594,6.30078125 C78.1205078,5.60390625 78.6289062,4.26230469 78.2660156,3.00976563 Z" id="Shape" fill="#E9E9E9"></path>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Waylf5Icon'
})
</script>
