import ApiResponseErrorType from '@/data/api/response-error/apiResponseErrorType'
import String from '@/data/utils/string'
import ApiResponseErrorModel from '@/data/api/response-error/model/apiResponseErrorModel'

// MARK: - Enum

export default class ApiResponseError {
  // MARK: - Properties

  /** @type {int} */
  _type
  /** @type {string|null} */
  _message

  // MARK: - Constructor

  constructor (statusCode, data = null) {
    switch (statusCode) {
      default:
        this._type = ApiResponseErrorType.UNKNOW
        break
    }

    // Check if error contains violations
    if (data) {
      const error = new ApiResponseErrorModel(data)
      if (error.isValid) {
        if (error.violations && error.violations.length > 0) {
          this._message = error.violations.map(e => {
            return e.message
          }).join(String.linebreak)
        } else {
          this._message = error.detail
        }
      }
    }
  }

  // MARK: - Getter

  /** @return {int} */
  get type () {
    return this._type
  }

  /** @return {string|null} */
  get message () {
    return this._message
  }
}
