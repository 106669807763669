<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Card -->
      <custom-row :view-model="viewModel.itemRow">
        <custom-col
            v-for="(item, i) in viewModel.offerCards"
            :key="i"
            :view-model="viewModel.itemCol"
        >
          <custom-card :view-model="item"/>
        </custom-col>
      </custom-row>

      <!-- Card space -->
      <custom-space :view-model="viewModel.offerCardsBottomSpace"/>

      <!-- Trial Text -->
      <p
          v-html="viewModel.trialText"
          :class="viewModel.trialClasses"
      />

    </custom-col>
  </custom-row>
</template>

<script>
import AppPricesViewModel from '@/presentation/scene/app/view-model/appPricesViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/scene/app/component/prices/AppPricesOfferCardComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'

export default {
  name: 'AppPricesComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent,
    customSpace: SpaceComponent
  },

  props: {
    viewModel: {
      type: AppPricesViewModel,
      required: true
    }
  }
}
</script>
