<template>
  <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="web/pictos/flame" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(15.000000, 0.000000)" fill-rule="nonzero">
        <path d="M56.1121772,32.5635051 C51.1421784,26.8914474 46.8742386,21.7851485 46.8742386,14.8277595 C46.8742386,11.2024854 48.2182018,7.75234522 50.6160148,5.06289591 C51.434576,4.18570292 51.5510782,2.84097836 51.0256762,1.78788986 C50.4401193,0.676930994 49.2712903,0.092897076 48.0430678,0.209399376 C45.4708821,0.56042885 40.0325903,4.59536433 35.179855,10.6755604 C34.7701938,11.1438536 34.361294,11.6113852 34.0102643,12.1375487 C28.0465704,19.9728924 21.6732152,33.0896686 22.6669103,51.1574074 C18.9251341,46.6549404 16.6445846,39.2871285 17.5217776,32.7386392 C17.6969115,31.2766509 16.7610867,29.8732943 15.2998598,29.5230263 C13.8378715,29.1719969 12.3758832,29.9905579 11.8497197,31.3939144 C10.0960953,36.2474111 8.16657524,40.1056897 6.3535575,43.7895955 C2.96204912,50.4553485 0.0974658869,56.2439084 0.0974658869,64.7226791 C0.0974658869,84.0765107 15.8260234,100 35.179855,100 C54.5336865,100 70.2622441,84.0765107 70.2622441,64.7226791 C70.2622441,48.8182261 62.7200596,40.1643214 56.1121772,32.5635051 Z" id="Shape" fill="#E9E9E9"></path>
        <path d="M70.2622441,64.7226791 C70.2622441,84.0765107 54.5336865,100 35.179855,100 L35.179855,10.6755604 C40.0333517,4.59536433 45.4708821,0.56042885 48.0438292,0.209399376 C49.2712903,0.092897076 50.4408809,0.676930994 51.0256762,1.78788986 C51.5518398,2.84097836 51.434576,4.18570292 50.6160148,5.06289591 C48.2189632,7.75234522 46.8742386,11.2024854 46.8742386,14.8277595 C46.8742386,21.7851485 51.1421784,26.8914474 56.1129386,32.5635051 C62.7200596,40.1643214 70.2622441,48.8182261 70.2622441,64.7226791 Z" id="Shape" fill="#FFFFFF"></path>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Waylf2Icon'
})
</script>
