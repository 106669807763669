<template>
    <!-- Text -->
    <p :class="viewModel.textClasses">
      {{ viewModel.text }}
    </p>
</template>

<script>
import AppFooterViewModel from '@/presentation/scene/app/view-model/appFooterViewModel'

export default {
  name: 'AppFooterComponent',

  props: {
    viewModel: {
      type: AppFooterViewModel,
      required: true
    }
  }
}
</script>
