<template>
  <custom-card :view-model="viewModel">

    <!-- Image -->
    <custom-image :view-model="viewModel.image"/>

    <!-- Title -->
    <v-card-title>
      {{ viewModel.title }}
    </v-card-title>

    <!-- Description -->
    <v-card-subtitle>
      {{ viewModel.description }}
    </v-card-subtitle>

    <!-- Offers -->
    <v-card-text>
      <custom-chip-group :view-model="viewModel.offers" />
    </v-card-text>

    <!-- Price -->
    <v-card-text :class="viewModel.priceClasses">
      {{ viewModel.priceValue }}
    </v-card-text>

  </custom-card>
</template>

<script>
import AppPricesOfferCardViewModel from '@/presentation/scene/app/view-model/prices/appPricesOfferCardViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import ImageComponent from '@/app/common/component/image/ImageComponent'
import ChipGroupComponent from '@/app/common/component/chip/ChipGroupComponent'

export default {
  name: 'AppPricesOfferItemCardComponent',

  components: {
    customCard: CardComponent,
    customImage: ImageComponent,
    customChipGroup: ChipGroupComponent
  },

  props: {
    viewModel: {
      type: AppPricesOfferCardViewModel,
      required: true
    }
  }
}
</script>
