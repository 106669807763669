import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import Color from '@/presentation/utils/color'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import AppContactNameTextFieldViewModel
from '@/presentation/scene/app/view-model/contact/appContactNameTextFieldViewModel'
import AppContactMessageTextAreaViewModel
from '@/presentation/scene/app/view-model/contact/appContactMessageTextAreaViewModel'
import AppContactEmailTextFieldViewModel
from '@/presentation/scene/app/view-model/contact/appContactEmailTextFieldViewModel'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.CONTACT

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 7
    }

    static Title = {
      isCentered: false,
      key: 'contact_title',
      boldKey: 'contact_title_bold'
    }

    static Space = {
      content: PresentationSpace.LITTLE,
      section: PresentationSpace.MEDIUM
    }

    static Text = {
      key: 'contact_description',
      formKey: 'contact_description_2'
    }

    static LinkButton = {
      messengerColor: Color.blue,

      classes: 'mr-2 mb-2 ',
      otherClasses: Color.textColor(Color.black),
      messengerClasses: Color.textColor(Color.white),

      phone: 'contact_call_phone',
      email: 'contact_send_email',
      messenger: 'contact_messenger'
    }

    static FromCard = {
      isStyleForMobile: true,
      classes: 'pa-sm-4 ' + Color.grey
    }

    static FormSplitCol = {
      cols: 12,
      lg: 6
    }

    static FormTexts = {
      name: 'contact_name',
      email: 'contact_email',
      message: 'contact_message'
    }

    static FormSendButton = {
      text: 'contact_send',
      classes: Color.textColor(Color.black)
    }
}

// MARK: - Class

export default class AppContactViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _text
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {RowViewModel} */
    _linkItemRow
    /** @type {[TextButtonViewModel]} */
    _linkButtons
    /** @type {SpaceViewModel} */
    _linkButtonsBottomSpace
    /** @type {string} */
    _formText
    /** @type {SpaceViewModel} */
    _formTextBottomSpace
    /** @type {CardViewModel} */
    _formCard
    /** @type {RowViewModel} */
    _formRow
    /** @type {ColViewModel} */
    _formSplitCol
    /** @type {ColViewModel} */
    _formFullCol
    /** @type {TextFieldViewModel} */
    _formNameTextField
    /** @type {TextFieldViewModel} */
    _formEmailTextField
    /** @type {TextAreaViewModel} */
    _formMessageTextArea
    /** @type {TextButtonViewModel} */
    _formSendButton

    // MARK: - Constructor

    /**
     * @constructor
     * @param {any} validation
     */
    constructor (validation) {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)
      this._title.isCentered = Constants.Title.isCentered

      this._text = Localization.getText(Constants.Text.key)

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.content

      this._linkItemRow = new RowViewModel()

      const linksButtonTitles = [Constants.LinkButton.email, Constants.LinkButton.messenger]
      this._linkButtons = linksButtonTitles.map(e => {
        const linkButton = new TextButtonViewModel()
        linkButton.title = Localization.getText(e)

        // Change color for 'messenger' button
        let linkButtonClasses = Constants.LinkButton.classes
        if (e === Constants.LinkButton.messenger) {
          linkButtonClasses += Constants.LinkButton.messengerClasses
          linkButton.color = Constants.LinkButton.messengerColor
        } else {
          linkButtonClasses += Constants.LinkButton.otherClasses
        }
        linkButton.classes = linkButtonClasses

        return linkButton
      })

      this._linkButtonsBottomSpace = new SpaceViewModel()
      this._linkButtonsBottomSpace.space = Constants.Space.section

      this._formText = Localization.getText(Constants.Text.formKey)

      this._formTextBottomSpace = new SpaceViewModel()
      this._formTextBottomSpace.space = Constants.Space.content

      this._formCard = new CardViewModel()
      this._formCard.isStyleForMobile = Constants.FromCard.isStyleForMobile
      this._formCard.classes = Constants.FromCard.classes

      this._formRow = new RowViewModel()

      this._formSplitCol = new ColViewModel()
      this._formSplitCol.cols = Constants.FormSplitCol.cols
      this._formSplitCol.lgAndGreater = Constants.FormSplitCol.lg

      this._formFullCol = new ColViewModel()

      this._formNameTextField = new AppContactNameTextFieldViewModel(validation)
      this._formEmailTextField = new AppContactEmailTextFieldViewModel(validation)
      this._formMessageTextArea = new AppContactMessageTextAreaViewModel(validation)

      this._formSendButton = new TextButtonViewModel()
      this._formSendButton.title = Localization.getText(Constants.FormSendButton.text)
      this._formSendButton.classes = Constants.FormSendButton.classes
    }

    // MARK: - Getter

    /** @return {array} */
    static get textFieldsValidations () {
      return Object.assign(
        AppContactNameTextFieldViewModel.validation,
        AppContactEmailTextFieldViewModel.validation,
        AppContactMessageTextAreaViewModel.validation
      )
    }

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {RowViewModel} */
    get linkItemRow () {
      return this._linkItemRow
    }

    /** @return {[TextButtonViewModel]} */
    get linkButtons () {
      return this._linkButtons
    }

    /** @return {SpaceViewModel} */
    get linkButtonsBottomSpace () {
      return this._linkButtonsBottomSpace
    }

    /** @return {string} */
    get formText () {
      return this._formText
    }

    /** @return {SpaceViewModel} */
    get formTextBottomSpace () {
      return this._formTextBottomSpace
    }

    /** @return {CardViewModel} */
    get formCard () {
      return this._formCard
    }

    /** @return {RowViewModel} */
    get formRow () {
      return this._formRow
    }

    /** @return {ColViewModel} */
    get formSplitCol () {
      return this._formSplitCol
    }

    /** @return {ColViewModel} */
    get formFullCol () {
      return this._formFullCol
    }

    /** @return {TextFieldViewModel} */
    get formNameTextField () {
      return this._formNameTextField
    }

    /** @return {TextFieldViewModel} */
    get formEmailTextField () {
      return this._formEmailTextField
    }

    /** @return {TextAreaViewModel} */
    get formMessageTextArea () {
      return this._formMessageTextArea
    }

    /** @return {TextButtonViewModel} */
    get formSendButton () {
      return this._formSendButton
    }
}
