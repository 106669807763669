<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Card -->
      <custom-row :view-model="viewModel.coachItemRow">
        <custom-col
            v-for="(item, i) in viewModel.coachCards"
            :key="i"
            :view-model="viewModel.coachItemCol"
        >
          <custom-card
              :view-model="item"
              @socialClick="socialClicked(i, $event)"
          />
        </custom-col>
      </custom-row>

      <!-- Card space -->
      <custom-space :view-model="viewModel.coachItemBottomSpace"/>

      <!-- Text -->
      <p v-html="viewModel.text"/>

      <!-- Text space -->
      <custom-space :view-model="viewModel.textBottomSpace"/>

      <!-- Social Network Text -->
      <p v-html="viewModel.socialText"/>

      <!-- Social buttons space -->
      <custom-row :view-model="viewModel.socialRow">
        <div
            v-for="(item, i) in viewModel.socialIconButtons"
            :key="i"
        >
            <custom-icon-button
                :view-model="item"
                @click="socialClicked(i)"
            />
        </div>
      </custom-row>

    </custom-col>
  </custom-row>
</template>

<script>
import AppWawViewModel from '@/presentation/scene/app/view-model/appWawViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/scene/app/component/waw/AppWawCardComponent'
import IconButtonComponent from '@/app/common/component/button/IconButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppWawComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent,
    customIconButton: IconButtonComponent
  },

  props: {
    viewModel: {
      type: AppWawViewModel,
      required: true
    }
  },

  methods: {
    socialClicked (index) {
      this.$emit(Events.socialClicked, index)
    }
  }
}
</script>
