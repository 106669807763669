import AppHomeScheduleDataModel from '@/data/repository/app/model/appHomeScheduleDataModel'

export default class AppHomePeriodScheduleDataModel {
    /** @type {string} */
    _period
    /** @type {[AppHomeScheduleDataModel]} */
    _values

    // MARK: - Constructor

    /**
     * @constructor
     * @param {any} response corresponding to ArrayAPIResponse.schedules[x]
     */
    constructor (response) {
      this._period = response.period
      this._values = response.values.map(e => {
        return new AppHomeScheduleDataModel(e)
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get period () {
      return this._period
    }

    /** @return {[AppHomeScheduleDataModel]} */
    get values () {
      return this._values
    }
}
