<template>
  <v-spacer :class="viewModel.classes"/>
</template>

<script>
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'

export default {
  name: 'SpaceComponent',

  props: {
    viewModel: {
      type: SpaceViewModel,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'space-style'
</style>
