<template>
  <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
    <title>web/pictos/cross-in-square</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path d="M42.3770492,16.8032787 L33.1967213,16.8032787 L33.1967213,7.78688525 C33.1967213,3.93442623 29.8360656,0.409836066 25.9836066,0.409836066 L23.6065574,0.409836066 C19.7540984,0.409836066 16.8032787,3.93442623 16.8032787,7.78688525 L16.8032787,16.8032787 L7.21311475,16.8032787 C3.36065574,16.8032787 0.409836066,20.3278689 0.409836066,24.1803279 L0.409836066,26.557377 C0.409836066,30.4090164 3.36065574,33.1967213 7.21311475,33.1967213 L16.8032787,33.1967213 L16.8032787,42.9508197 C16.8032787,46.802459 19.7540984,49.5901639 23.6065574,49.5901639 L25.9836066,49.5901639 C29.8360656,49.5901639 33.1967213,46.8032787 33.1967213,42.9508197 L33.1967213,33.1967213 L42.3770492,33.1967213 C46.2295082,33.1967213 49.5901639,30.4098361 49.5901639,26.557377 L49.5901639,24.1803279 C49.5901639,20.3278689 46.2295082,16.8032787 42.3770492,16.8032787 Z" id="path-cross-1"></path>
      <path d="M100,83.6065574 L100,16.3934426 C100,7.37704918 92.6229508,0 83.6065574,0 L16.3934426,0 C7.37704918,0 0,7.37704918 0,16.3934426 L0,83.6065574 C0,92.6229508 7.37704918,100 16.3934426,100 L83.6065574,100 C92.6229508,100 100,92.6229508 100,83.6065574 Z M88.5245902,83.6065574 C88.5245902,86.3114754 86.3114754,88.5245902 83.6065574,88.5245902 L16.3934426,88.5245902 C13.6885246,88.5245902 11.4754098,86.3114754 11.4754098,83.6065574 L11.4754098,16.3934426 C11.4754098,13.6885246 13.6885246,11.4754098 16.3934426,11.4754098 L83.6065574,11.4754098 C86.3114754,11.4754098 88.5245902,13.6885246 88.5245902,16.3934426 L88.5245902,83.6065574 Z" id="path-cross-3"></path>
    </defs>
    <g id="web/pictos/cross-in-square" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group">
        <g id="Path" transform="translate(25.000000, 25.000000)">
          <mask id="mask-cross-2" fill="white">
            <use xlink:href="#path-cross-1"></use>
          </mask>
          <use id="Mask" fill="#E9E9E9" fill-rule="nonzero" xlink:href="#path-cross-1"></use>
          <rect fill="#FFFFFF" fill-rule="nonzero" mask="url(#mask-cross-2)" x="25" y="-25" width="50" height="100"></rect>
        </g>
        <g id="Path">
          <mask id="mask-cross-4" fill="white">
            <use xlink:href="#path-cross-3"></use>
          </mask>
          <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" xlink:href="#path-cross-3"></use>
          <rect fill="#E9E9E9" fill-rule="nonzero" mask="url(#mask-cross-4)" x="50" y="0" width="50" height="100"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Waylf6Icon'
})
</script>
