<template>
  <v-btn
      :icon="viewModel.isIcon"
      :class="viewModel.classes"
      :color="viewModel.color"
      @click="clicked()">
    <custom-icon :view-model="viewModel.icon"/>
  </v-btn>
</template>

<script>
import IconButtonViewModel from '@/presentation/view-model/button/iconButtonViewModel'
import IconComponent from '@/app/common/component/icon/IconComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'IconButtonButtonComponent',

  components: {
    customIcon: IconComponent
  },

  props: {
    viewModel: {
      type: IconButtonViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
