import AppHomePeriodScheduleDataModel from '@/data/repository/app/model/appHomePeriodScheduleDataModel'

export default class AppHomeDataModel {
  /** @type {[AppHomePeriodScheduleDataModel]} */
  _periodSchedules

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._periodSchedules = response.schedules.map(e => {
      return new AppHomePeriodScheduleDataModel(e)
    })
  }

  // MARK: - Getter

  /** @return {[AppHomePeriodScheduleDataModel]} */
  get periodSchedules () {
    return this._periodSchedules
  }
}
