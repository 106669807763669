import Localization from '@/presentation/utils/localization'

export default class TabViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _key
    /** @type {string|null} */
    _name
    /** @type {string|null} */
    _anchor

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._key = null
      this._name = null
      this._anchor = null
    }

    // MARK: - Getter

    /** @return {string|null} */
    get key () {
      return this._key
    }

    /** @return {string|null} */
    get name () {
      return this._name
    }

    /** @return {string|null} */
    get anchor () {
      return this._anchor
    }

    // MARK: - Setter

    /** @param {string} value */
    set nameKey (value) {
      this._name = Localization.getText(value)
    }

    /** @param {string} value */
    set name (value) {
      this._name = value
    }

    /** @param {string} value */
    set anchor (value) {
      this._key = value
      this._anchor = value
    }
}
