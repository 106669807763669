<template>
  <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="web/pictos/bodybuilding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group">
        <g fill-rule="nonzero" id="Shape">
          <g>
            <path d="M97.8007797,25.9986355 L86.8091618,7.23138402 C84.1968811,2.77095517 79.362768,-7.10542736e-15 74.1937622,-7.10542736e-15 L54.579922,-7.10542736e-15 C51.3500975,-7.10542736e-15 48.7319688,2.61812865 48.7319688,5.84795322 L48.7319688,20.4666667 C48.7319688,23.6964912 51.3502924,26.3148148 54.580117,26.3146199 L64.3274854,26.31423 L67.0565302,24.5614035 L70.1754386,26.3959064 C72.9415205,26.8596491 75.0499025,29.2637427 75.0499025,32.1615984 L75.0499025,61.8380117 L74.7249513,61.5596491 C71.0177388,58.3820663 66.2873294,56.6321637 61.4046784,56.6321637 C56.7050682,56.6321637 52.1421053,58.2538012 48.5011696,61.2087719 L44.834308,60.8187135 L44.7561404,56.7105263 C39.7923977,51.9189084 33.0859649,49.1235867 26.0124756,49.1235867 C26.0118908,49.1235867 26.0099415,49.1235867 26.0093567,49.1235867 C11.6888889,49.1253411 0.0343079922,60.760039 -5.54030021e-15,75.0727096 C-0.0325536062,88.6758285 11.311306,99.8050682 24.914425,99.8050682 L54.8547758,99.8050682 C69.7863548,99.8050682 84.765692,96.3307992 98.1730994,89.7576998 C99.1730994,89.2674464 99.8052632,88.2483431 99.8052632,87.1345029 L99.8052632,33.3873294 C99.8050682,30.7931774 99.1118908,28.2382066 97.8007797,25.9986355 Z" fill="#FFFFFF"></path>
            <g transform="translate(35.087719, 0.000000)" fill="#E9E9E9">
              <path d="M62.7130604,25.9986355 L51.7214425,7.23138402 C49.1091618,2.77095517 44.2750487,-7.10542736e-15 39.1060429,-7.10542736e-15 L19.4929825,-7.10542736e-15 C16.2633528,-7.10542736e-15 13.6452242,2.61812865 13.6450292,5.84775828 L13.6446394,20.4664717 C13.6444444,23.6962963 16.262963,26.3148148 19.4927875,26.3146199 L29.2397661,26.31423 L31.9688109,24.5614035 L35.0877193,26.3959064 C37.8538012,26.8596491 39.9621832,29.2637427 39.9621832,32.1615984 L39.9621832,61.8380117 L39.637232,61.5596491 C35.9300195,58.3820663 31.1996101,56.6321637 26.3169591,56.6321637 C22.2023392,56.6321637 18.1925926,57.8752437 14.8146199,60.1678363 L14.8146199,99.8050682 L19.7666667,99.8050682 C34.6982456,99.8050682 49.6775828,96.3307992 63.0849903,89.7576998 C64.0849903,89.2674464 64.717154,88.2483431 64.717154,87.1345029 L64.717154,33.3873294 C64.7173489,30.7931774 64.0241715,28.2382066 62.7130604,25.9986355 Z"></path>
            </g>
          </g>
        </g>
        <path d="M26.0233918,43.2748538 C24.408577,43.2748538 23.0994152,41.965692 23.0994152,40.3508772 L23.0994152,32.5536062 C23.0994152,30.9387914 24.408577,29.6296296 26.0233918,29.6296296 C27.6382066,29.6296296 28.9473684,30.9387914 28.9473684,32.5536062 L28.9473684,40.3508772 C28.9473684,41.965692 27.6382066,43.2748538 26.0233918,43.2748538 Z" id="Shape" fill="#E9E9E9" fill-rule="nonzero"></path>
        <path d="M36.2573099,42.8830409 C34.8588694,42.0756335 34.3797271,40.2873294 35.1871345,38.8888889 L39.08577,32.1362573 C39.8931774,30.7378168 41.6814815,30.2586745 43.079922,31.0660819 C44.4783626,31.8734893 44.9575049,33.6617934 44.1500975,35.0602339 L40.251462,41.8128655 C39.4440546,43.211306 37.6557505,43.6904483 36.2573099,42.8830409 Z" id="Shape" fill="#E9E9E9" fill-rule="nonzero"></path>
        <path d="M11.7953216,41.8128655 L7.89668616,35.0602339 C7.08927875,33.6617934 7.56842105,31.8734893 8.9668616,31.0660819 C10.3653021,30.2586745 12.1536062,30.7378168 12.9610136,32.1362573 L16.8596491,38.8888889 C17.6670565,40.2873294 17.1879142,42.0756335 15.7894737,42.8830409 C14.3910331,43.6904483 12.602729,43.211306 11.7953216,41.8128655 Z" id="Shape" fill="#E9E9E9" fill-rule="nonzero"></path>
        <g transform="translate(7.407407, 30.604288)"></g>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Waylf4Icon'
})
</script>
