<template>
  <!-- Textarea component -->
  <v-textarea
      v-model="vuelidate.$model"
      :label="viewModel.label"
      :error-messages="viewModel.errorMessage"
      :required="viewModel.required"
      :placeholder="viewModel.placeholder"
      :type="viewModel.type"
      :color="viewModel.color"
      :outlined="viewModel.outlined"
      :clearable="viewModel.clearable"
      :autocomplete="viewModel.autocomplete"
      :disabled="viewModel.disabled"
      @input="vuelidate.$touch()"
      @blur="vuelidate.$touch()"
  />
</template>

<script>
import TextAreaViewModel from '@/presentation/view-model/textarea/textAreaViewModel'

export default {
  name: 'TextAreaComponent',

  props: {
    viewModel: {
      type: TextAreaViewModel,
      required: true
    },

    vuelidate: {
      required: true
    }
  }
}
</script>
