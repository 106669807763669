// MARK: - Constants

class Constants {
  static nameKey = 'name'
  static messageKey = 'message'
  static emailKey = 'email'
}

// MARK: - Class

export default class ContactAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} name
   * @param {string} message
   * @param {string} email
   */
  constructor (name, message, email) {
    this._body[Constants.nameKey] = name
    this._body[Constants.messageKey] = message
    this._body[Constants.emailKey] = email
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
