// MARK: - Constants

class Constants {
  static defaultSize = 12
}

// MARK: - Class

export default class ColViewModel {
  // MARK: - Properties

  /** @type {int|null} */
  _cols
  /** @type {int|null} */
  _sm
  /** @type {int|null} */
  _md
  /** @type {int|null} */
  _lg
  /** @type {int|null} */
  _xl
  /** @type {string|null} */
  _classes

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._classes = null
    this._cols = Constants.defaultSize
    this._sm = null
    this._md = null
    this._lg = null
    this._xl = null
    this._colsOffset = null
    this._smOffset = null
    this._mdOffset = null
    this._lgOffset = null
    this._xlOffset = null
  }

  // MARK: - Getter

  /** @return {int|null} */
  get cols () {
    return this._cols
  }

  /** @return {int|null} */
  get sm () {
    return this._sm
  }

  /** @return {int|null} */
  get md () {
    return this._md
  }

  /** @return {int|null} */
  get lg () {
    return this._lg
  }

  /** @return {int|null} */
  get xl () {
    return this._xl
  }

  /** @return {int} */
  get colsOffset () {
    return this._colsOffset
  }

  /** @return {int|null} */
  get smOffset () {
    return this._smOffset
  }

  /** @return {int|null} */
  get mdOffset () {
    return this._mdOffset
  }

  /** @return {int|null} */
  get lgOffset () {
    return this._lgOffset
  }

  /** @return {int|null} */
  get xlOffset () {
    return this._xlOffset
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  // MARK: - Setter

  /** @param {string} value */
  set classes (value) {
    this._classes = value
  }

  /** @param {int|null} value */
  set cols (value) {
    this._cols = value
  }

  /** @param {int} value */
  set smAndGreater (value) {
    this._sm = value
  }

  /** @param {int} value */
  set mdAndGreater (value) {
    this._md = value
  }

  /** @param {int} value */
  set lgAndGreater (value) {
    this._lg = value
  }

  /** @param {int} value */
  set xl (value) {
    this._xl = value
  }

  /** @param {int} value */
  set colsOffset (value) {
    this._colsOffset = value
  }

  /** @param {int} value */
  set smOffsetAndGreater (value) {
    this._smOffset = value
  }

  /** @param {int} value */
  set mdOffsetAndGreater (value) {
    this._mdOffset = value
  }

  /** @param {int} value */
  set lgOffsetAndGreater (value) {
    this._lgOffset = value
  }

  /** @param {int} value */
  set xlOffsetAndGreater (value) {
    this._xlOffset = value
  }
}
