// MARK: - Constants

class Constants {
    static keyPrefix = 'background-space-'
}

const ONE = Constants.keyPrefix + 'one'
const TWO = Constants.keyPrefix + 'two'

// MARK: - Enum

export default {
  ONE,
  TWO
}
