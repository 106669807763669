import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
    static bold = true

    static boldClasses = 'font-weight-bold'

    static color = Color.main
}

// MARK: - Class

export default class Style {
  // MARK: - Properties

    /** @type {string} */
    _text
    /** @type {string} */
    _classes

    // MARK: - Constructor

    /**
     * @constructor
     * @param {string} textKey
     * @param {string|null} color
     * @param {boolean} bold
     */
    constructor (textKey,
      color = Constants.color,
      bold = Constants.bold) {
      this._text = Localization.getText(textKey)
      const colorClasses = color ? Color.textColor(color) : String.empty
      const boldClasses = bold ? Constants.boldClasses : String.empty
      this._classes = [colorClasses, boldClasses].join(String.space)
    }

    // MARK: - Getter

    /**
     * @param {string} value
     * @return {string}
     **/
    addStyleOnText (value) {
      return value.replaceAll(this._text, '<span class="' + this._classes + '">' + this._text + '</span>')
    }

    /**
     * @param {string} value
     * @param {[Style]} styles
     * @return {string}
     **/
    static addStylesOnText (value, styles) {
      styles.forEach(e => {
        value = value.replaceAll(e._text, '<span class="' + e._classes + '">' + e._text + '</span>')
      })
      return value
    }
}
