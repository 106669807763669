<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

        <!-- Title -->
        <custom-section-title :view-model="viewModel.title"/>

        <!-- Text -->
        <p v-html="viewModel.text"/>

        <!-- Asset space -->
        <custom-space :view-model="viewModel.textBottomSpace"/>

        <!-- Asset Buttons -->
        <custom-row :view-model="viewModel.assetItemRow">
          <div
              v-for="(item, i) in viewModel.assetButtons"
              :key="i"
          >
            <custom-button
                :view-model="item"
                @click="assetClicked(i)"
            />
          </div>
        </custom-row>

    </custom-col>
  </custom-row>
</template>

<script>
import AppConceptViewModel from '@/presentation/scene/app/view-model/appConceptViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppConceptComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customButton: TextButtonComponent
  },

  props: {
    viewModel: {
      type: AppConceptViewModel,
      required: true
    }
  },

  methods: {
    assetClicked (index) {
      this.$emit(Events.assetClicked, index)
    }
  }
}
</script>
