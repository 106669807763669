import HomeAPITarget from '@/data/api/target/homeAPITarget'
import DataError from '@/data/error/dataError'
import ContactAPITarget from '@/data/api/target/contactAPITarget'
import AppHomeDataModel from '@/data/repository/app/model/appHomeDataModel'

export default class AppDataRepository {
  // MARK: - Properties

  /** @type {ContactAPITarget} */
  _contactApiTarget
  /** @type {HomeAPITarget} */
  _homeApiTarget

  // MARK: - Constructor

  constructor () {
    this._contactApiTarget = new ContactAPITarget()
    this._homeApiTarget = new HomeAPITarget()
  }

  // MARK: - Getter

  /**
   * Get home values
   * @return {Promise<AppHomeDataModel>}
   */
  home () {
    const self = this
    return new Promise(function (resolve, reject) {
      self._homeApiTarget.home().then(response => {
        const object = new AppHomeDataModel(response)
        resolve(object)
      }).catch(e => {
        reject(e)
      })
    })
  }

  /**
   * Update current password
   * @param {AppHomeContactDataEntry} parameter
   */
  contact (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._contactApiTarget.contact(parameter.toAPIBody).then(_ => {
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }
}
