import AppWawCoachCardViewModel from '@/presentation/scene/app/view-model/waw/appWawCoachCardViewModel'
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'

// MARK: - Constants

const ROMAIN = 1
const FANNY = 2
const CHARLINE = 3
const GWEN = 4

// MARK: - Enum

export default {
  ROMAIN,
  FANNY,
  CHARLINE,
  GWEN,

  /**
     * @return {[int]} return all enum values
     */
  all () {
    return [
      ROMAIN,
      FANNY,
      CHARLINE,
      GWEN
    ]
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {AppWawCoachCardViewModel}
     */
  card (value) {
    const avatar = new ImageViewModel()
    avatar.src = this._avatar(value)

    const item = new AppWawCoachCardViewModel()
    item.avatar = avatar
    item.name = this._name(value)
    return item
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _avatar (value) {
    return 'img/coach/' + value + '.jpeg'
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _name (value) {
    switch (value) {
      case FANNY:
        return 'FANNY'
      case CHARLINE:
        return 'CHARLINE'
      case GWEN:
        return 'GWEN'
      default:
        return 'ROMAIN'
    }
  }
}
