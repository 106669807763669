<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Description -->
      <p>{{ viewModel.text }}</p>

      <!-- Description space -->
      <custom-space :view-model="viewModel.textBottomSpace"/>

      <!-- Link Buttons -->
      <custom-row :view-model="viewModel.linkItemRow">
        <div
            v-for="(item, i) in viewModel.linkButtons"
            :key="i"
        >
          <custom-button
              :view-model="item"
              @click="linkClicked(i)"
          />
        </div>
      </custom-row>

      <!-- Link Buttons space -->
      <custom-space :view-model="viewModel.linkButtonsBottomSpace"/>

      <!-- Form Description -->
      <p>{{ viewModel.formText }}</p>

      <!-- Description space -->
      <custom-space :view-model="viewModel.formTextBottomSpace"/>

      <!-- Form -->
      <custom-card :view-model="viewModel.formCard">

        <!-- First line -->
        <custom-row :view-model="viewModel.formRow">

          <!-- Name -->
          <custom-col :view-model="viewModel.formSplitCol">
            <custom-text-field
                :view-model="viewModel.formNameTextField"
                :vuelidate="vuelidate.contactFormName"
            />
          </custom-col>

          <!-- Email -->
          <custom-col :view-model="viewModel.formSplitCol">
            <custom-text-field
                :view-model="viewModel.formEmailTextField"
                :vuelidate="vuelidate.contactFormEmail"
            />
          </custom-col>
        </custom-row>

        <!-- Second line -->
        <custom-row :view-model="viewModel.formRow">

          <!-- Message -->
          <custom-col :view-model="viewModel.formFullCol">
            <custom-text-area
                :view-model="viewModel.formMessageTextArea"
                :vuelidate="vuelidate.contactFormMessage"
            />
          </custom-col>
        </custom-row>

        <!-- Last line -->
        <custom-row :view-model="viewModel.formRow">

          <!-- Send -->
          <custom-col :view-model="viewModel.formFullCol">
            <custom-button
                :view-model="viewModel.formSendButton"
                @click="sendClicked()"
            />
          </custom-col>
        </custom-row>

      </custom-card>

    </custom-col>
  </custom-row>
</template>

<script>
import AppContactViewModel from '@/presentation/scene/app/view-model/appContactViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import CardComponent from '@/app/common/component/card/CardComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'
import TextAreaComponent from '@/app/common/component/textarea/TextAreaComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppContactComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customButton: TextButtonComponent,
    customCard: CardComponent,
    customTextField: TextFieldComponent,
    customTextArea: TextAreaComponent
  },

  props: {
    viewModel: {
      type: AppContactViewModel,
      required: true
    },

    vuelidate: {
      required: true
    }
  },

  methods: {
    linkClicked (index) {
      this.$emit(Events.contactLinkClicked, index)
    },

    sendClicked () {
      this.$emit(Events.contactSendClicked)
    }
  }
}
</script>
