<template>
  <v-navigation-drawer
      v-model="viewModel.showContent"
      :absolute="viewModel.absolute"
      :temporary="viewModel.temporary"
      v-click-outside="closeNavigationClicked"
  >
    <v-list
        :nav="viewModel.listNav"
        :dense="viewModel.listDense"
    >
      <v-list-item-group
          v-model="viewModel.listSelectedIndex"
          :active-class="viewModel.listActiveClass">
        <v-list-item
            v-for="item in viewModel.listItems"
            :key="item.path"
            @click="navigationClicked(item.anchor)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavigationViewModel from '@/presentation/view-model/navigation/navigationViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'NavigationComponent',

  props: {
    viewModel: {
      type: NavigationViewModel,
      required: true
    }
  },

  methods: {
    closeNavigationClicked () {
      this.$emit(Events.closeNavigationClicked)
    },

    navigationClicked (anchor) {
      this.$emit(Events.navigationClicked, anchor)
    }
  }
}
</script>
