import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import NavigationItemViewModel from '@/presentation/view-model/navigation/navigationItemViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static defaultShowContent = false
  static absolute = true
  static temporary = true

  static List = {
    selectedIndex: 0,
    nav: true,
    dense: true,
    activeClasses: Color.textColor(Color.main)
  }
}

// MARK: - Class

export default class NavigationViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _showContent
  /** @type {boolean} */
  _absolute
  /** @type {boolean} */
  _temporary
  /** @type {int} */
  _listSelectedIndex
  /** @type {boolean} */
  _listNav
  /** @type {boolean} */
  _listDense
  /** @type {string} */
  _listActiveClass
  /** @type {[NavigationItemViewModel]} */
  _listItems

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._showContent = Constants.defaultShowContent
    this._absolute = Constants.absolute
    this._temporary = Constants.temporary

    this._listSelectedIndex = Constants.List.selectedIndex
    this._listNav = Constants.List.nav
    this._listDense = Constants.List.dense
    this._listActiveClass = Constants.List.activeClasses

    this._listItems = []
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showContent () {
    return this._showContent
  }

  /** @return {boolean} */
  get absolute () {
    return this._absolute
  }

  /** @return {boolean} */
  get temporary () {
    return this._temporary
  }

  /** @return {int} */
  get listSelectedIndex () {
    return this._listSelectedIndex
  }

  /** @return {boolean} */
  get listNav () {
    return this._listNav
  }

  /** @return {boolean} */
  get listDense () {
    return this._listDense
  }

  /** @return {string} */
  get listActiveClass () {
    return this._listActiveClass
  }

  /** @return {[NavigationItemViewModel]} */
  get listItems () {
    return this._listItems
  }

  // MARK: - Setter

  /** @param {int} value */
  set listSelectedIndex (value) {
    this._listSelectedIndex = value
  }

  /** @param {[int]} value corresponding to [PresentationNavigation] */
  set items (value) {
    this._listItems = value.map(e => {
      const item = new NavigationItemViewModel()
      item.nameKey = PresentationNavigation.nameKey(e)
      item.anchor = PresentationNavigation.anchorKey(e)
      return item
    })
  }

  /** @param {boolean} value */
  set showContent (value) {
    this._showContent = value
  }
}
