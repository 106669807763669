import ContactAPIBody from '@/data/api/body/contactAPIBody'

export default class AppHomeContactDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _name
  /** @type {string} */
  _message
  /** @type {string} */
  _email

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} name
   * @param {string} message
   * @param {string} email
   */
  constructor (name, message, email) {
    this._name = name
    this._message = message
    this._email = email
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {ContactAPIBody}
   */
  get toAPIBody () {
    return new ContactAPIBody(this._name, this._message, this._email)
  }
}
