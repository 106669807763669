// MARK: - Constants

import TimelineItemViewModel from '@/presentation/view-model/timeline/timelineItemViewModel'
import Color from '@/presentation/utils/color'
import Localization from '@/presentation/utils/localization'

class Constants {
    static Card = {
      classes: 'pa-4 pa-md-8 rounded ' + Color.grey,
      elevation: 4
    }

    static Title = {
      classes: 'headline mb-4 '
    }
}

// MARK: - Class

export default class AppWaylfTimelineItemViewModel extends TimelineItemViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _cardClasses
    /** @type {int} */
    _cardElevation
    /** @type {string|null} */
    _title
    /** @type {string|null} */
    _description

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._cardClasses = Constants.Card.classes
      this._cardElevation = Constants.Card.elevation
      this._title = null
      this._description = null
    }

    // MARK: - Getter

    /** @return {string|null} */
    get cardClasses () {
      return this._cardClasses
    }

    /** @return {int} */
    get cardElevation () {
      return this._cardElevation
    }

    /** @return {string|null} */
    get titleClasses () {
      return Constants.Title.classes + Color.textColor(this.color)
    }

    /** @return {string|null} */
    get title () {
      return this._title
    }

    /** @return {string|null} */
    get description () {
      return this._description
    }

    // MARK: - Setter

    /** @param {string|null} value */
    set cardClasses (value) {
      this._cardClasses = value
    }

    /** @param {int} value */
    set cardElevation (value) {
      this._cardElevation = value
    }

    /** @param {string|null} value */
    set titleKey (value) {
      this._title = Localization.getText(value)
    }

    /** @param {string|null} value */
    set descriptionKey (value) {
      this._description = Localization.getText(value)
    }
}
