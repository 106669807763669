<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Card -->
      <custom-row :view-model="viewModel.itemRow">
        <custom-col
            v-for="(item, i) in viewModel.periodSchedulesCards"
            :key="i"
            :view-model="viewModel.itemCol"
        >
          <custom-card :view-model="item"/>
        </custom-col>
      </custom-row>

      <!-- Account Text -->
      <p :class="viewModel.accountTextClasses">
        {{ viewModel.accountText }}
      </p>

      <!-- Account Button -->
      <custom-button
          :view-model="viewModel.accountButton"
          @click="accountClicked()"
      />

    </custom-col>
  </custom-row>
</template>

<script>
import AppSchedulesViewModel from '@/presentation/scene/app/view-model/appSchedulesViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/scene/app/component/schedules/AppSchedulesDayCardComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppSchedulesComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent,
    customButton: TextButtonComponent
  },

  props: {
    viewModel: {
      type: AppSchedulesViewModel,
      required: true
    }
  },

  methods: {
    accountClicked () {
      this.$emit(Events.accountClicked)
    }
  }
}
</script>
