<template>
  <div :class="viewModel.classes">
    <!-- Big screen Logo -->
    <custom-image
      v-if="viewModel.showBigImage"
      :view-model="viewModel.bigImage"
    />

    <!-- Little screen Logo -->
    <custom-image
      v-if="viewModel.showLittleImage"
      :view-model="viewModel.littleImage"
    />
  </div>
</template>

<script>
import AppLogoViewModel from '@/presentation/scene/app/view-model/appLogoViewModel'
import ImageComponent from '@/app/common/component/image/ImageComponent'

export default {
  name: 'AppLogoComponent',

  components: {
    customImage: ImageComponent
  },

  props: {
    viewModel: {
      type: AppLogoViewModel,
      required: true
    }
  }
}
</script>
