import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
    static defaultValue = 1
    static activeClasses = Color.textColor(Color.main)
    static column = true
}

// MARK: - Class

export default class ChipGroupViewModel {
  // MARK: - Properties

    /** @type {int|string} */
    _selectedValue
    /** @type {string|null} */
    _activeClasses
    /** @type {boolean} */
    _column
    /** @type {[ChipViewModel]} */
    _items

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._selectedValue = Constants.defaultValue
      this._activeClasses = Constants.activeClasses
      this._column = Constants.column
      this._items = []
    }

    // MARK: - Getter

    /** @return {int|string} */
    get selectedValue () {
      return this._selectedValue
    }

    /** @return {string} */
    get activeClasses () {
      return this._activeClasses
    }

    /** @return {boolean} */
    get column () {
      return this._column
    }

    /** @return {[ChipViewModel]} */
    get items () {
      return this._items
    }

    // MARK: - Setter

    /** @param {int|string} value */
    set selectedValue (value) {
      this._selectedValue = value
    }

    /** @param {[ChipViewModel]} value */
    set items (value) {
      this._items = value
    }
}
