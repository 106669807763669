import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
    static Text = {
      key: 'footer_information',
      classes: 'px-2 pt-12 pb-6 text-caption text--disabled text-center'
    }
}

// MARK: - Class

export default class AppFooterViewModel {
  // MARK: - Properties

    /** @type {string} */
    _text
    /** @type {string} */
    _textClasses

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      const currentYear = new Date().getFullYear()
      this._text = Localization.getText(Constants.Text.key, currentYear)
      this._textClasses = Constants.Text.classes
    }

    // MARK: - Getter

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {string} */
    get textClasses () {
      return this._textClasses
    }
}
