import AppManager from '@/common/manager/appManager'

// MARK: - Class

export default class TimelineViewModel {
  // MARK: - Properties

    /** @type {[TimelineItemViewModel]} */
    _items

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._items = []
    }

    // MARK: - Getter

    /** @return {boolean} */
    get dense () {
      return AppManager.shared.vuetify.breakpoint.smAndDown
    }

    /** @return {[TimelineItemViewModel]} */
    get items () {
      return this._items
    }

    // MARK: - Setter

    /** @param {[TimelineItemViewModel]} value */
    set items (value) {
      this._items = value
    }
}
