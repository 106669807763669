// MARK: - Constants

class Constants {
    static TextClasses = {
      day: 'text-subtitle-2 font-weight-black text-md-center',
      times: 'text-h6 text-md-center'
    }
}

// MARK: - Class

export default class AppSchedulesDayCardTimeViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _day
    /** @type {string|null} */
    _dayClasses
    /** @type {string|null} */
    _times
    /** @type {string|null} */
    _timeClasses

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._day = null
      this._dayClasses = Constants.TextClasses.day

      this._times = null
      this._timeClasses = Constants.TextClasses.times
    }

    // MARK: - Getter

    /** @return {string|null} */
    get day () {
      return this._day
    }

    /** @return {string|null} */
    get dayClasses () {
      return this._dayClasses
    }

    /** @return {string|null} */
    get times () {
      return this._times
    }

    /** @return {string|null} */
    get timesClasses () {
      return this._timeClasses
    }

    // MARK: - Setter

    /** @param {string|null} value */
    set day (value) {
      this._day = value
    }

    /** @param {string|null} value */
    set times (value) {
      this._times = value
    }
}
