import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
    static Path = {
      contact: 'contacts/question'
    }
}

// MARK: - Class

export default class ContactAPITarget {
  // MARK: - Properties

    /** @type {ApiWorker} */
    _worker

    // MARK: - Constructor

    constructor () {
      const configuration = new ApiConfiguration()
      this._worker = new ApiWorker(configuration)
    }

    // MARK: - Post

    /**
     * Call API to log client
     * @param {ContactAPIBody} parameter
     * @return {Promise}
     */
    contact (parameter) {
      return this._worker.postRequest(Constants.Path.contact, parameter)
    }
}
