// MARK: - Constants

class Constants {
    static classes = 'background-space '
    static width = '100%'
    static height = '400px'
}

// MARK: - Class

export default class AppSpaceBackgroundViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _classes
    /** @type {string|int|null} */
    _width
    /** @type {string|int|null} */
    _height

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._classes = null
      this._width = Constants.width
      this._height = Constants.height
    }

    // MARK: - Getter

    /** @return {string|null} */
    get classes () {
      return Constants.classes + this._classes
    }

    /** @return {string|int|null} */
    get width () {
      return this._width
    }

    /** @return {string|int|null} */
    get height () {
      return this._height
    }

    // MARK: - Setter

    /** @param {string|null} value */
    set classes (value) {
      this._classes = value
    }
}
