// MARK: - Constants

import AppHomeDomainModel from '@/domain/scene/app/model/appHomeDomainModel'
import AppHomeContactDomainEntry from '@/domain/scene/app/appHomeContactDomainEntry'

class Constants {
  static defaultShowContent = false

  static Tab = {
    other: '_blank',
    same: '_self'
  }

  static AssetUrl = {
    images: 'https://www.facebook.com/Energy-Training-882818241834493/photos',
    movies: 'https://www.facebook.com/Energy-Training-882818241834493/videos'
  }

  static StoreUrl = {
    ios: 'https://apps.apple.com/fr/app/energy-training/id1569666745',
    android: 'https://play.google.com/store/apps/details?id=fr.energytraining.client.twa'
  }

  static MapUrl = {
    apple: 'https://maps.apple.com/?q=47.251332,-1.504763&sll=47.251332,-1.504763&sspn=0.001735,0.004807',
    google: 'https://goo.gl/maps/tAXkstDUmAEsiaNV6'
  }

  static Social = {
    facebook: 'https://www.facebook.com/energytrainingnantes/',
    instagram: 'https://www.instagram.com/energy_training_nantes/'
  }

  static Rating = {
    value: 4.5,
    facebook: 'https://www.facebook.com/Energy-Training-882818241834493/reviews'
  }

  static ContactUrl = {
    messenger: 'http://m.me/energytrainingnantes',
    phone_number: 'tel:+33665943380',
    email: 'mailto:contact@energytraining.fr'
  }
}

// MARK: - Class

export default class AppInteractor {
  // MARK: - Properties

  /** @type {AppDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {AppPresenter} */
  _presenter

  /** @type {boolean} */
  _showNavigationMenu

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {AppPresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter

    this._showNavigationMenu = Constants.defaultShowContent
  }
  // MARK: - Life cycle vue

  vueMounted () {
    // Update size on icon on app bar
    this._presenter.updateAppBarLogoSizeFromInteractor()
    // Update selected item on app bar
    this._presenter.updateSelectedNavigationItemFromInteractor()

    // Get home values from repository
    this._recoverHomeFromRepository()

    // Set opinion
    this._presenter.opinionFromInteractor = Constants.Rating.value
  }

  // MARK: From Controller

  menuTappedFromController () {
    this._showNavigationMenu = !this._showNavigationMenu
    this._presenter.showMenuFromInteractor(this._showNavigationMenu)
  }

  accountTappedFromController () {
    // Go to client app
    this._openUrl(process.env.VUE_APP_CLIENT_URL)
  }

  /**
   * @param {string} anchor to scroll to correct div
   */
  navigationItemTappedFromController (anchor) {
    // Scroll to specific div
    this._presenter.scrollToAnchorFromInteractor(anchor)

    // Hide menu
    this._showNavigationMenu = false
    this._presenter.showMenuFromInteractor(this._showNavigationMenu)
  }

  closeNavigationTappedFromController () {
    if (this._showNavigationMenu) {
      this._showNavigationMenu = false
    }
  }

  resizeFiredFromController () {
    this._presenter.updateAppBarLogoSizeFromInteractor()
  }

  scrollFiredFromController () {
    // Update size on icon on app bar
    this._presenter.updateAppBarLogoSizeFromInteractor()
    // Update selected item on app bar
    this._presenter.updateSelectedNavigationItemFromInteractor()
  }

  /**
   * @param {int} index tapped (0: photos, 1: videos)
   */
  assetTappedFromController (index) {
    const url = (index === 0) ? Constants.AssetUrl.images : Constants.AssetUrl.movies
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: facebook, 1: instagram)
   */
  socialTappedFromController (index) {
    const url = (index === 0) ? Constants.Social.facebook : Constants.Social.instagram
    this._openUrl(url)
  }

  readOpinionFromController () {
    this._openUrl(Constants.Rating.facebook)
  }

  /**
   * @param {int} index tapped (0: app store, 1: play store)
   */
  storeTappedFromController (index) {
    const url = (index === 0) ? Constants.StoreUrl.ios : Constants.StoreUrl.android
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: apple plan, 1: google maps)
   */
  mapTappedFromController (index) {
    const url = (index === 0) ? Constants.MapUrl.apple : Constants.MapUrl.google
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: call phone, 1: send email, 2: Facebook Manager)
   */
  contactLinkTappedFromController (index) {
    // Call phone number in same tab
    if (index === 0) {
      window.open(Constants.ContactUrl.phone_number, Constants.Tab.same)
    } else { // Open url on other tab
      let url
      switch (index) {
        case 1:
          url = Constants.ContactUrl.email
          break
        default:
          url = Constants.ContactUrl.messenger
          break
      }
      this._openUrl(url)
    }
  }

  contactSendTappedFromController () {
    // Form is valid ?
    if (!this._validation.$invalid) {
      // Disabled edition of the form
      this._presenter.enableContactFormFromInteractor = false

      // Show loader
      this._presenter.showContactLoaderFromInteractor = true

      const self = this
      const entry = new AppHomeContactDomainEntry(this._validation)
      this._repository.contact(entry.toData).then(function () {
        // Enabled edition of the form
        self._presenter.enableContactFormFromInteractor = true
        // Clear form
        self._presenter.clearContactTextFieldsFromInteractor()
        // Hide loader
        self._presenter.showContactLoaderFromInteractor = false
        // Show success
        self._presenter.prepareContactSuccessFromInteractor()
      }).catch(_ => {
        // Enabled edition of the form
        self._presenter.enableContactFormFromInteractor = true
        // Hide loader
        self._presenter.showContactLoaderFromInteractor = false
        // Show error
        self._presenter.prepareContactErrorFromInteractor()
      })
    } else {
      // Show error on all invalids text fields
      this._presenter.showContactTextFieldsErrorFromInteractor()
    }
  }

  // MARK: - To Repository

  /**
   * Get home values from repository
   */
  _recoverHomeFromRepository () {
    const self = this
    this._repository.home().then(function (result) {
      const home = new AppHomeDomainModel(result)

      // Convert to domain model array and send it to presenter
      self._presenter.schedulesFromInteractor = home.periodSchedules
    }).catch(_ => {
      // Show error
      self._presenter.prepareHomeErrorFromInteractor()
    })
  }

  // MARK: - Others

  /**
   * @param {string} url to go
   */
  _openUrl (url) {
    window.open(url, Constants.Tab.other)
  }
}
