import AppPricesOfferCardViewModel from '@/presentation/scene/app/view-model/prices/appPricesOfferCardViewModel'
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import ChipViewModel from '@/presentation/view-model/chip/chipViewModel'

// MARK: - Constants

const ILLIMITED = 1
const LIMITED = 2
const STUDENTS = 3
const FAMILIES = 4

// MARK: - Enum

export default {
  ILLIMITED,
  LIMITED,
  STUDENTS,
  FAMILIES,

  /**
     * @return {[int]} return all enum values
     */
  all () {
    return [
      ILLIMITED,
      LIMITED,
      STUDENTS,
      FAMILIES
    ]
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {AppPricesOfferCardViewModel}
     */
  card (value) {
    const image = new ImageViewModel()
    image.src = this._image(value)

    const item = new AppPricesOfferCardViewModel()
    item.image = image
    item.titleKey = this._titleKey(value)
    item.descriptionKey = this._descriptionKey(value)
    item.pricePrefixValue = this._offerPrefixKey(value)

    const items = []
    for (let i = 1; i <= this._numberOfOffers(value); i++) {
      const item = new ChipViewModel()
      item.value = i
      item.textKey = this._offerPrefixKey(value) + i
      items.push(item)
    }
    item.offersItems = items

    return item
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _image (value) {
    return 'img/prices/' + value + '.jpg'
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _titleKey (value) {
    return 'prices_' + value + '_subtitle'
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string|null}
     */
  _descriptionKey (value) {
    switch (value) {
      case LIMITED:
      case STUDENTS:
        return 'prices_' + value + '_description'
      default:
        return null
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {int}
     */
  _numberOfOffers (value) {
    switch (value) {
      case LIMITED:
        return 3
      case STUDENTS:
        return 4
      case FAMILIES:
        return 3
      default:
        return 7
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  _offerPrefixKey (value) {
    return 'prices_' + value + '_'
  }
}
