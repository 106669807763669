import AppDomainInjection from '@/domain/scene/app/appDomainInjection'
import AppController from '@/presentation/scene/app/appController'
import AppPresenter from '@/presentation/scene/app/appPresenter'

export default class AppPresentationInjection {
  // MARK: - Properties

  /** @type {AppController} */
  _controller
  /** @type {AppPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation
   * @param {any} refs
   */
  constructor (validation, refs) {
    this._presenter = new AppPresenter(validation, refs)

    // Domain
    const domainInjection = new AppDomainInjection(validation, this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new AppController(interactor)
  }

  // MARK: - Getter

  /** @return {AppController} */
  get controller () {
    return this._controller
  }

  /** @return {AppPresenter} */
  get presenter () {
    return this._presenter
  }
}
