import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static Path = {
    home: 'home/values'
  }
}

// MARK: - Class

export default class HomeAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration()
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Post

  /**
   * Call API to get home values
   * @return {Promise}
   */
  home () {
    return this._worker.getRequest(Constants.Path.home)
  }
}
