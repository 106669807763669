import Color from '@/presentation/utils/color'
import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
  static value = 0
  static color = Color.main
  static halfIncrements = true
  static readonly = true
  static length = 5

  static Size = {
    min: 35,
    max: 60
  }
}

// MARK: - Class

export default class RatingViewModel {
  // MARK: - Properties

  /** @type {number} */
  _value
  /** @type {string} */
  _color
  /** @type {boolean} */
  _halfIncrements
  /** @type {boolean} */
  _readonly
  /** @type {int} */
  _length
  /** @type {int|string} */
  _size

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._value = Constants.value
    this._color = Constants.color
    this._halfIncrements = Constants.halfIncrements
    this._readonly = Constants.readonly
    this._length = Constants.length
    this._size = Constants.size
  }

  // MARK: - Getter

  /** @return {number} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {boolean} */
  get halfIncrements () {
    return this._halfIncrements
  }

  /** @return {boolean} */
  get readonly () {
    return this._readonly
  }

  /** @return {int} */
  get length () {
    return this._length
  }

  /** @return {int|string} */
  get size () {
    return AppManager.shared.vuetify.breakpoint.smAndDown ? Constants.Size.min : Constants.Size.max
  }

  // MARK: - Setter

  /** @param {number} value */
  set value (value) {
    this._value = value
  }
}
