import PresentationKey from '@/presentation/enum/presentationKey'

// MARK: - Constants

class Constants {
    static id = PresentationKey.scrolling
    static classes = 'top-margin fill-height overflow-y-auto'
    static fluid = true
    static style = 'height: 100vh;'
}

// MARK: - Class

export default class BodyViewModel {
  // MARK: - Properties

    /** @type {string} */
    _id
    /** @type {string} */
    _classes
    /** @type {boolean} */
    _fluid
    /** @type {string} */
    _style

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._id = PresentationKey.value(Constants.id)
      this._classes = Constants.classes
      this._fluid = Constants.fluid
      this._style = Constants.style
    }

    // MARK: - Getter

    /** @return {string} */
    get id () {
      return this._id
    }

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {boolean} */
    get fluid () {
      return this._fluid
    }

    /** @return {string} */
    get style () {
      return this._style
    }
}
